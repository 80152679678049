import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import TableView from './TableView'
import AddMar from "./AddMar";
import ResultNotFound from './ResultNotFound'
import { get_all_users_list_mar, get_all_medications, get_all_member_list_mar, get_all_master_medication_list, get_all_master_units_list, get_all_master_does_form_list, get_all_master_coding_system_list, get_all_master_route_list, get_all_master_type_list } from '../../../redux/services/AuthMar'
import { setUserPermissions } from '../../../redux/features/commonSlice';

const initionalSearch = {
  approval_status: '',
  admin_status: '',
  admin_date: '',
  page: 1,
  listData: 10,
  searchBar: ''
}
const MAR = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const totalPages = useSelector(state => state?.AuthMar?.AllMedications?.totalNoPages);
  const marlist = useSelector(state => state?.AuthMar?.AllMedications?.marlist);
  const moduleData = useSelector(state => state.common.moduleData);
  const userPermissions = useSelector(state => state.common.userPermissions);

  const [search, setSearch] = useState(initionalSearch);
  const [addNewModal, setAddNewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(get_all_users_list_mar())
		dispatch(get_all_member_list_mar())
		dispatch(get_all_master_medication_list())
		dispatch(get_all_master_units_list())
		dispatch(get_all_master_does_form_list())
		dispatch(get_all_master_coding_system_list())
		dispatch(get_all_master_route_list())
		dispatch(get_all_master_type_list())
	}, [])

  useEffect(() => {
    if (moduleData.length > 0) {      
      const module = moduleData.find(item => item.module_name === 'MAR');
      if (module) {
        const permissionsArray = module.permission.map(item => item.permission);
        dispatch(setUserPermissions(permissionsArray));
      }
    }
  }, [moduleData, dispatch]);

  useEffect(() => {
    if(!addNewModal){
      dispatch(get_all_medications(search))
    }
  }, [addNewModal, search])

  const handleAddNewToggale = (value) => {
    setAddNewModal(value)
  }

  const handleSearchBar = (e) => {
    setSearch({
      ...search,
      page: 1,
      searchBar: e.target.value,
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    setSearch({
      ...search,
      page: pageNumber
    });
  };

  const handleRowClick = (id) => {
    if (userPermissions?.includes('View')) {
    navigate(`/auth/edit-mar/${id}`)
    }
  }
  
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
              <Header handleSearchBar={handleSearchBar} search={search} setSearch={setSearch} handleAddNew={handleAddNewToggale} />
              {marlist && marlist.length === 0 ?
              <ResultNotFound/>
              :
              <TableView marList={marlist} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} search={search} onRowClick={handleRowClick} />
              }
              {addNewModal && <AddMar onModalToggle={handleAddNewToggale} />}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
export default MAR
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { DashboardSvg, MemberSvg, AppointmentsSvg, CommunicationSvg, MarSvg, AdminSvg, HomesSvg, UsersSvg, RolesAndPermissionsSvg, SettingsSvg, NotificationsSvg, TasksSvg } from '../../Images/SidebarSvg';

const urls = {
    Dashboard: '/auth/dashboard',
    Homes: '/auth/homes',
    Users: '/auth/users',
    Member: '/auth/members',
    Appointments: '/auth/appointment',
    Communication: '/auth/communication',
    Documents: '/auth/documents',
    MAR: '/auth/mar',
    RolesAndPermissions: '/auth/settings',
    Settings: '/auth/settings',
    Tasks: '/auth/tasks',
    Notifications: '/auth/notification'
};

const images = {
    Dashboard: <DashboardSvg />,
    Homes: <HomesSvg />,
    Users: <UsersSvg />,
    Member: <MemberSvg />,
    Appointments: <AppointmentsSvg />,
    Communication: <CommunicationSvg />,
    Documents: <DashboardSvg />,
    MAR: <MarSvg />,
    'Roles and permissions': <RolesAndPermissionsSvg />,
    Settings: <SettingsSvg />,
    Admin: <AdminSvg />,
    Notifications: <NotificationsSvg />,
    Tasks: <TasksSvg />
};

const Siderbar = () => {
    let location = useLocation();
    const moduleData = useSelector((state) => state?.common?.moduleData);
    const subscriptionPlan = useSelector(state => state?.suscription?.planInfo);
    const BoardViewTasks = useSelector(state => state?.AuthTasks?.BoardViewTasks);
    const [menuItems, setMenuItems] = useState([]);
    const [activeMenu, setActiveMenu] = useState('Dashboard');
    const [activeSubMenu, setActiveSubMenu] = useState('');
    const [taskPendingCount, setTaskPendingCount] = useState(0);
    const tab = localStorage.getItem('tabs')

useEffect(()=>{
    if(BoardViewTasks){
        let pending = BoardViewTasks?.pending
        if(pending){
            setTaskPendingCount(pending.length)
        }
    }
    
},[BoardViewTasks])

    useEffect(() => {
        let path = location.pathname
        if (path.includes('home')) {
            setActiveMenu('Homes')
        }
        if (path.includes('settings')) {
            setActiveMenu('Settings')
            if (tab === 'roles') {
                setActiveMenu('Admin')
                setActiveSubMenu('Roles and permissions')
            } else {
                setActiveMenu('Settings')
                setActiveSubMenu('')
            }
        }else{
            setActiveSubMenu('')
        }
        if (path.includes('dashboard')) {
            setActiveMenu('Dashboard')
        }
        if (path.includes('users')) {
            setActiveMenu('Users')
        }
        if (path.includes('members')) {
            setActiveMenu('Member')
        }
        if (path.includes('edit-member')) {
            setActiveMenu('Member')
        }
        if (path.includes('edit-members')) {
            setActiveMenu('Member')
        }
        if (path.includes('mar')) {
            setActiveMenu('MAR')
        }
        if (path.includes('communication')) {
            setActiveMenu('Communication')
        }
        if (path.includes('appointment')) {
            setActiveMenu('Appointments')
        }
        if (path.includes('documents')) {
            setActiveMenu('Documents')
        }
        if (path.includes('tasks')) {
            setActiveMenu('Tasks')
        }
        if (path.includes('notification')) {
            setActiveMenu('Notifications')
        }
    }, [location.pathname, tab])

    useEffect(() => {
        let menus = [...moduleData];
        menus.splice(0, 0, { id: 'dashboard', module_name: 'Dashboard', module_description: 'Dashboard Desc' });

        // Add Admin menu if the user has access to it
        if (subscriptionPlan?.roleData?.roles !== 'user') {
            menus.push({
                id: 'admin',
                module_name: 'Admin',
                module_description: 'Admin Desc',
                subMenu: [
                    {
                        id: 'roles',
                        module_name: 'Roles and permissions',
                        url: urls.RolesAndPermissions,
                    }
                ],
            }
            );
        }

        menus.push(
            {
                id: 'settings',
                module_name: 'Settings',
                module_description: 'Settings Desc',
            }
        );

        setMenuItems(menus);
    }, [moduleData, subscriptionPlan]);

    const handleActiveMenu = (item) => {
        localStorage.removeItem('subscribe')
        localStorage.removeItem('tabs')
        if (item === 'Settings') {
            localStorage.setItem('tabs', 'organization');
        }
        if (item === 'Roles and permissions') {
            localStorage.setItem('tabs', 'roles')
        }
        setActiveMenu(item);
    };

    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        {
                            menuItems && menuItems.map((ele) => {                                                            
                                if (ele.subMenu) {
                                    // If the menu item has submenus (e.g., Admin), render it with a collapse option
                                    return (
                                        <React.Fragment key={ele.id}>
                                            <a className={`nav-link collapsed`}
                                                href="#" data-bs-toggle="collapse" data-bs-target={`#collapse${ele.id}`}
                                                aria-expanded="false" aria-controls={`collapse${ele.id}`}>
                                                <div className="sb-nav-link-icon">
                                                    {images[ele.module_name]}
                                                </div>
                                                {ele.module_name} 
                                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>
                                            <div className="collapse" id={`collapse${ele.id}`} aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                                <nav className="sb-sidenav-menu-nested nav">
                                                    {ele.subMenu.map((sub) => (
                                                        <Link key={sub.id} className={`nav-link ${activeSubMenu === sub?.module_name ? 'active' : ''}`}
                                                            to={sub.url} onClick={() => handleActiveMenu(sub?.module_name)}>
                                                            <div className="sb-nav-link-icon">
                                                                {images[sub.module_name]}
                                                            </div>
                                                            {sub.module_name}
                                                        </Link>
                                                    ))}
                                                </nav>
                                            </div>
                                        </React.Fragment>
                                    );
                                } else {                                   
                                    // Regular menu items
                                    return (
                                        <Link key={ele.id} className={`nav-link ${activeMenu === ele?.module_name ? 'active-menu' : ''}`}
                                            to={urls[ele?.module_name]} onClick={() => handleActiveMenu(ele?.module_name)}>
                                            <div className="sb-nav-link-icon">
                                                {images[ele.module_name]}
                                            </div>
                                            {ele?.module_name}
                                            {taskPendingCount !== 0 && ele?.module_name === 'Tasks' && <span className='sidebar_count'>{taskPendingCount}</span>}
                                        </Link>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Siderbar;

import React from 'react'
import memberEmpty from '../../../Images/member-empty.png'
import { Link } from 'react-router-dom'
const ResultNotFound = () => {
    return (
        <div className="main-content-sec">
            <div className="empty-sec mb-3">
                <div className="font-16 hd-title mb-3">Result not found</div>
                <img src={memberEmpty} alt="" title="" />
                {/* <div className="hd-subtitle">Currently no members that have been added yet. To see the list of members, you can begin by adding one using the button below.</div> */}
                {/* <Link to='/auth/add-member' className="btn btn-primary">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                New member
            </Link> */}
            </div>
        </div>
    )
}

export default ResultNotFound
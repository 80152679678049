import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscription } from '../../../redux/features/subscription';
import { getSubscriptionPlan, getSubscriptionPlanDiscount } from '../../../redux/services/subscription-plan';
const Subscription = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectYear, setSelectYear] = useState(0)
    useEffect(() => {
        dispatch(getSubscriptionPlan());
        dispatch(getSubscriptionPlanDiscount());
    }, [dispatch])
    //get subscription plan list
    const subscriptionPlan = useSelector(state => state?.suscription);
    const subscriptionPlanDiscount = useSelector(state => state?.suscription?.planDiscount);
    const subScriptionPlanList = subscriptionPlan?.subscriptionPlanList
    const choose_plan = (name, amount, id, monthAmount, yearlyPriceWithDiscount) => {
        dispatch(setSubscription({ name, amount, selectYear, id, monthAmount, yearlyPriceWithDiscount }));
        navigate('/setting/subscription-page');
    }
    const formattedCurrency = (currency) => {
        // let pricee = 15450
        const price = currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        return price
    }
    return (
        <div className="subscriptions-page">
            <section className="subscriptions-sec">
                <div className="subscriptions-inner container">
                    <div className="sub-plans-toggler">
                        <form action="">
                            <div className="form-check" onClick={() => setSelectYear(0)}>
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectYear === 0 ? true : false} />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Monthly billing <span className="text-success">{subscriptionPlanDiscount?.month ? `(Save up to ${subscriptionPlanDiscount?.month}%)` : ''}</span>
                                </label>
                            </div>
                            <div className="form-check" onClick={() => setSelectYear(1)}>
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={selectYear === 1 ? true : false} />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Yearly billing <span className="text-success">{subscriptionPlanDiscount?.year ? `(Save up to ${subscriptionPlanDiscount?.year}%)` : ''}</span>
                                </label>
                            </div>
                        </form>
                    </div>
                    <div className="sub-plans-head">
                        {subScriptionPlanList && subScriptionPlanList.map((elem, i) => {
                            const monthlyPrice = elem?.subscription_plan_msrp_price - subscriptionPlanDiscount?.month / 100;
                            const yearlyPrice = elem?.subscription_plan_msrp_price * 12;
                            const discount = yearlyPrice * subscriptionPlanDiscount?.year / 100;
                            const yearlyPriceWithDiscount = parseFloat(yearlyPrice) - parseFloat(discount);
                            return (
                                <>
                                    {selectYear === 0 ?
                                        <>
                                            {elem?.duration_type === 'month' &&
                                                <div className="sub-plans-inner">
                                                    <div className="font-20 hd-title">{elem?.subscription_plan_name}{i == 1 ? <span className="badge text-bg-success">Most popular</span> : ''} </div>
                                                    <div className="hd-subtitle font-16">{elem?.subscription_plan_desc}</div>
                                                    <div className="font-36 hd-title">{selectYear == 1 ? formattedCurrency(yearlyPriceWithDiscount) : formattedCurrency(monthlyPrice)}<span className="month-text">/{selectYear == 1 ? 'Yr' : 'Mo'}</span></div>
                                                    <button onClick={() => choose_plan(elem?.subscription_plan_name, selectYear == 1 ? yearlyPriceWithDiscount : elem?.subscription_plan_msrp_price, elem?.id, elem?.subscription_plan_msrp_price,
                                                        yearlyPriceWithDiscount, subscriptionPlanDiscount?.year)} className="btn btn-outline-primary">Subscribe</button>
                                                    <div className="sub-plans-list-head">
                                                        {
                                                            elem?.modules?.map((ele) => {
                                                                return (
                                                                    <div className="splh-inner">
                                                                        <div className="font-16 hd-subtitle">{ele?.module_name}</div>
                                                                        <div className="tooltipp">
                                                                            <span className="tooltipptext">{ele?.module_description}</span>
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M10.3333 13.3337V10.0003M10.3333 6.66699H10.3417M18.6667 10.0003C18.6667 14.6027 14.9357 18.3337 10.3333 18.3337C5.73096 18.3337 2 14.6027 2 10.0003C2 5.39795 5.73096 1.66699 10.3333 1.66699C14.9357 1.66699 18.6667 5.39795 18.6667 10.0003Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            {elem?.duration_type === 'year' &&
                                                <div className="sub-plans-inner">
                                                    <div className="font-20 hd-title">{elem?.subscription_plan_name}{i == 1 ? <span className="badge text-bg-success">Most popular</span> : ''} </div>
                                                    <div className="hd-subtitle font-16">{elem?.subscription_plan_desc}</div>
                                                    <div className="font-36 hd-title">{selectYear == 1 ? formattedCurrency(yearlyPriceWithDiscount) : formattedCurrency(monthlyPrice)}<span className="month-text">/{selectYear == 1 ? 'Yr' : 'Mo'}</span></div>
                                                    <button onClick={() => choose_plan(elem?.subscription_plan_name, selectYear == 1 ? yearlyPriceWithDiscount : elem?.subscription_plan_msrp_price, elem?.id, elem?.subscription_plan_msrp_price,
                                                        yearlyPriceWithDiscount, subscriptionPlanDiscount?.year)} className="btn btn-outline-primary">Subscribe</button>
                                                    <div className="sub-plans-list-head">
                                                        {
                                                            elem?.modules?.map((ele) => {
                                                                return (
                                                                    <div className="splh-inner">
                                                                        <div className="font-16 hd-subtitle">{ele?.module_name}</div>
                                                                        <div className="tooltipp">
                                                                            <span className="tooltipptext">{ele?.module_description}</span>
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M10.3333 13.3337V10.0003M10.3333 6.66699H10.3417M18.6667 10.0003C18.6667 14.6027 14.9357 18.3337 10.3333 18.3337C5.73096 18.3337 2 14.6027 2 10.0003C2 5.39795 5.73096 1.66699 10.3333 1.66699C14.9357 1.66699 18.6667 5.39795 18.6667 10.0003Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </>

                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Subscription;

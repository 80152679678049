import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from "react-date-range";
import UrlBar from './UrlBar'
import { getMonthDate } from '../../../components/Common/Common'
import { ExportToExcel } from './ExportFile'
import { appointment_export } from '../../../redux/services/AuthAppointment'
import { appointmentStatusOptions } from './CommonData'
import moment from 'moment';

const Header = ({ handleSearchBar, onCalanderView, calanderView, calanderTabView, setCalanderTabView, setAddNewModal, selectionRange, setSelectionRange, search, setSearch, FromMember = '' }) => {
    const dispatch = useDispatch()

    const userPermissions = useSelector((state) => state?.common?.userPermissions);
    const AllAppointmentList = useSelector(state => state?.AuthAppointment?.AllAppointmentList?.appoinments);

    const [isOpen, setIsOpen] = useState(null);
    const [showFilter, setShowFilter] = useState(false);


    //export xsl file
    const exportToExcel = async () => {
        const response = await dispatch(appointment_export());
        if (response?.payload?.status == true) {
            ExportToExcel(response?.payload?.data, 'Appointment')
        }
    }

    const toggleCalendar = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setSelectionRange({ startDate, endDate, key: "selection", });
        setCalanderTabView(null)
    };

    const handleTabClick = (type) => {
        setCalanderTabView(type)
    }

    const handleStatusChange = (e) => {
        const { name, value } = e.target
        setSearch({
            ...search,
            [name]: value,
        });
    }

    const clear_all_filters = () => {

        setSearch({
            ...search,
            approved_status: '',
            appointment_status: '',
            page: 1,
            listData: 10,
            searchBar: ''
        });
    }


    return (
        <>
           {!FromMember && <UrlBar />}
            <div className="right-main-header adminstrat_btn_main">
               {!FromMember && <h2>Appointment</h2>}
               {!FromMember && <div className="adminstrat_btn">
                    <button type="button" className="btn btn-light" onClick={() => exportToExcel()}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6667 1.8916V5.33372C11.6667 5.80043 11.6667 6.03378 11.7575 6.21204C11.8374 6.36885 11.9649 6.49633 12.1217 6.57622C12.3 6.66705 12.5333 6.66705 13 6.66705H16.4422M7.50004 12.5003L10 15.0003M10 15.0003L12.5 12.5003M10 15.0003L10 10.0003M11.6667 1.66699H7.33337C5.93324 1.66699 5.23318 1.66699 4.6984 1.93948C4.22799 2.17916 3.84554 2.56161 3.60586 3.03202C3.33337 3.5668 3.33337 4.26686 3.33337 5.66699V14.3337C3.33337 15.7338 3.33337 16.4339 3.60586 16.9686C3.84554 17.439 4.22799 17.8215 4.6984 18.0612C5.23318 18.3337 5.93324 18.3337 7.33337 18.3337H12.6667C14.0668 18.3337 14.7669 18.3337 15.3017 18.0612C15.7721 17.8215 16.1545 17.439 16.3942 16.9686C16.6667 16.4339 16.6667 15.7338 16.6667 14.3337V6.66699L11.6667 1.66699Z"
                                stroke="#344054" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                        Export
                    </button>

                    {userPermissions && userPermissions.includes('Add') && <button type="button" className="btn btn-primary" onClick={() => setAddNewModal(true)}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white"
                                stroke-width="1.66667" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                        New Appointment
                    </button>}
                </div>}
            </div>
            {/* {AllAppointmentList && AllAppointmentList?.length > 0 && */}
            <div className="date_picker_container">
                <div className="saf-img-upload">
                    <button type="button" className="btn btn-light" onClick={() => toggleCalendar()}>
                        <svg width="18" height="20"
                            viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.5 8.33317H1.5M12.3333 1.6665V4.99984M5.66667 1.6665V4.99984M5.5 18.3332H12.5C13.9001 18.3332 14.6002 18.3332 15.135 18.0607C15.6054 17.821 15.9878 17.4386 16.2275 16.9681C16.5 16.4334 16.5 15.7333 16.5 14.3332V7.33317C16.5 5.93304 16.5 5.23297 16.2275 4.69819C15.9878 4.22779 15.6054 3.84534 15.135 3.60565C14.6002 3.33317 13.9001 3.33317 12.5 3.33317H5.5C4.09987 3.33317 3.3998 3.33317 2.86502 3.60565C2.39462 3.84534 2.01217 4.22779 1.77248 4.69819C1.5 5.23297 1.5 5.93304 1.5 7.33317V14.3332C1.5 15.7333 1.5 16.4334 1.77248 16.9681C2.01217 17.4386 2.39462 17.821 2.86502 18.0607C3.3998 18.3332 4.09987 18.3332 5.5 18.3332Z"
                                stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                        {getMonthDate(selectionRange.startDate === '' ? moment().startOf('month').toDate() : selectionRange.startDate)} - {getMonthDate(selectionRange.endDate === '' ? moment().endOf('month').toDate() : selectionRange.endDate)}</button>

                    <div className="member_day_main">
                        <div className="member_day_btn">
                            {/* {!showFilter && */}
                                <button type="button" className="btn btn-light" onClick={() => setShowFilter(!showFilter)}>
                                    <svg width="20" height="20"
                                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                            stroke-width="1.66667" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            {/* } */}

                            {calanderView === 'list' &&
                                <>
                                    <button type="button" className={`btn btn-light ${calanderTabView === 'day' ? 'badges' : ''}`} onClick={() => handleTabClick('day')}>{calanderTabView === 'day' && <span className="actives"></span>} Day</button>
                                    <button type="button" className={`btn btn-light ${calanderTabView === 'week' ? 'badges' : ''}`} onClick={() => handleTabClick('week')}>{calanderTabView === 'week' && <span className="actives"></span>} Week</button>
                                    <button type="button" className={`btn btn-light ${calanderTabView === 'month' ? 'badges' : ''}`} onClick={() => handleTabClick('month')}>{calanderTabView === 'month' && <span className="actives"></span>} Month</button>
                                </>
                            }

                            <select className="form-select" aria-label="Default select example" value={calanderView} onChange={(e) => onCalanderView(e.target.value)}>
                                <option value="list">List View</option>
                                <option value="calander">Calendar View</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="date_picker_under">
                    {isOpen && (
                        <div style={{ position: "relative", display: "inline-block" }}>
                            <button
                                onClick={() => toggleCalendar()}
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                }}
                            >
                                &#10005;
                            </button>
                            <DateRange
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                            />
                        </div>
                    )}
                </div>

                {showFilter &&

                    <div className="table-btn-sec more-filter">
                        <button type="button" className="btn btn-light" onClick={() => (setShowFilter(false), clear_all_filters())}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Close Filters
                        </button>
                        <button className='clear-filter' onClick={() => clear_all_filters()}>Clear all filters</button>
                        <div className="filter-option">
                            <div className="form-group searchbar">
                                <label className="form-label">Search</label>
                                <input type="text" name="" id="" className="form-control" placeholder="Search with..." onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20} />
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>

                            <div className="form-group">
                                <label className="form-label">Approval Status</label>
                                <select className="form-select" aria-label="Default select example" name="approved_status" value={search.approved_status} onChange={(e) => (handleStatusChange(e))} >
                                    <option value="">-Please Select-</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                </select>							</div>


                            <div className="form-group">
                                <label for="" className="form-label">Appointment Status</label>
                                <select className="form-select" aria-label="Default select example" name="appointment_status" value={search.appointment_status} onChange={(e) => (handleStatusChange(e))} >
                                    <option value="">Select</option>
                                    {appointmentStatusOptions.map((item) => <option value={item.value}>{item.lable}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>

                }

            </div>
            {/* } */}
        </>
    )
}
export default Header
import React from 'react'
import memberEmpty from '../../../../Images/member-empty.png'
const DataNotFound = ({activeTab}) => {
    return (
        <div className="">  
            <div className="main-content-sec">
                <div className="empty-sec">
                    <img src={memberEmpty} alt="" title="" />
                    <div className="font-16 hd-title">There are no {activeTab} records yet</div>
                </div>
            </div>
        </div>
    )
}

export default DataNotFound
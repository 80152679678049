import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscription } from '../../../../redux/features/subscription';
import { getSubscriptionPlan, getSubscriptionPlanDiscount } from '../../../../redux/services/subscription-plan';
import { getPlanFeatureActive } from '../../../../redux/services/AuthSettings';
import UpdateSubscription from './UpdateSubscription';
import DeleteSubscription from './DeleteSubscription';
import {PUBLISH_KEY} from '../../../../constant/Matcher'
import { Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const AuthSubscription = () => {
    const stripePromise = loadStripe(PUBLISH_KEY);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [DeleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectYear, setSelectYear] = useState(0)
    const [amount1, setAmount1] = useState('')
    useEffect(() => {
        dispatch(getPlanFeatureActive());
        dispatch(getSubscriptionPlanDiscount());
        dispatch(getSubscriptionPlan());
    }, [dispatch])
    //get subscription plan list
    const subscriptionPlanDiscount = useSelector(state => state?.suscription?.planDiscount);
    const subScriptionPlanList = useSelector(state => state?.AuthSettings?.subscriptionPlanList);
    const choose_plan = (name, amount, id, actionText, monthAmount, yearlyPriceWithDiscount) => {
        dispatch(setSubscription({ name, amount, actionText, selectYear, id, monthAmount, yearlyPriceWithDiscount }));
        setAmount1(amount)
        // navigate('/setting/subscription-page');
    }

    useEffect(()=>{
        if(subScriptionPlanList && subScriptionPlanList.length > 0){
            // Check for active subscription plans with year duration type
    subScriptionPlanList.forEach(plan => {
        if (plan.is_active && plan.duration_type === "year") {
            setSelectYear(1);  // Setting the year selection to 1
        }
    });
        }
    },[subScriptionPlanList])

    const formattedCurrency = (currency) => {
        // let pricee = 15450
        const price = currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        return price
    }        
    return (
        <>
            <div className="tab-pane fade show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="sub-plans-toggler">
                    <form action="">
                        <div className="form-check" onClick={() => setSelectYear(0)}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectYear === 0 ? true : false} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Monthly billing <span className="text-success">{subscriptionPlanDiscount?.month ? `(Save up to ${subscriptionPlanDiscount?.month}%)` : ''}</span>
                            </label>
                        </div>
                        <div className="form-check" onClick={() => setSelectYear(1)}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={selectYear === 1 ? true : false} />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Yearly billing <span className="text-success">{subscriptionPlanDiscount?.year ? `(Save up to ${subscriptionPlanDiscount?.year}%)` : ''}</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className="sub-plans-head">
                    {subScriptionPlanList && subScriptionPlanList.map((elem, i) => {
                        
                        const monthlyPrice = elem?.subscription_plan_msrp_price ? elem.subscription_plan_msrp_price - (elem.subscription_plan_msrp_price * (subscriptionPlanDiscount?.month / 100)) : 0;
                        const yearlyPrice = elem?.subscription_plan_msrp_price ? elem.subscription_plan_msrp_price * 12 : 0;
                        const discount = yearlyPrice ? yearlyPrice * (subscriptionPlanDiscount?.year / 100) : 0;
                        const yearlyPriceWithDiscount = yearlyPrice ? yearlyPrice - discount : 0;

                        // Finding the active plan and its price
                        const activePlan = elem?.billingPlan;
                        const activeStatus = elem?.is_active;
                        // Current active plan price
                        let currentActivePrice = 0;
                        if (activePlan) {
                            const activePlanPriceMonthly = activePlan?.subscriptionPlan?.subscription_plan_msrp_price ?? 0;
                            const activePlanDiscountMonthly = subscriptionPlanDiscount?.month / 100 ?? 0;
                            const activePlanPriceYearly = activePlanPriceMonthly * 12;
                            const activePlanDiscountYearly = activePlanPriceYearly * (subscriptionPlanDiscount?.year / 100 ?? 0);

                            currentActivePrice = selectYear == 1
                                ? activePlanPriceYearly - activePlanDiscountYearly
                                : activePlanPriceMonthly - (activePlanPriceMonthly * activePlanDiscountMonthly);
                        }

                        // Current plan price being displayed
                        const currentPrice = selectYear == 1 ? yearlyPriceWithDiscount : monthlyPrice;
                        // Logic for determining if it's an Upgrade or Downgrade
                        let actionText = activeStatus ? 'Subscribed' : (currentPrice <= currentActivePrice ? 'Downgrade' : 'Upgrade');
                        return (
                            <>
                                {selectYear === 0 ?
                                    <>
                                        {elem?.duration_type === 'month' &&
                                            <div className="sub-plans-inner" key={elem?.id}>
                                                {activeStatus ? <span className="badge text-bg-success active-plan">Active</span> : ''}
                                                <div className="font-20 hd-title">
                                                    {elem?.subscription_plan_name}{i === 1 ? <span className="badge text-bg-success">Most popular</span> : ''}
                                                </div>
                                                <div className="hd-subtitle font-16">{elem?.subscription_plan_desc}</div>
                                                <div className="font-36 hd-title">
                                                    {selectYear === 1 ? formattedCurrency(yearlyPriceWithDiscount) : formattedCurrency(monthlyPrice)}
                                                    <span className="month-text">/{selectYear === 1 ? 'Yr' : 'Mo'}</span>
                                                </div>
                                                <button
                                                    onClick={() => (choose_plan(
                                                        elem?.subscription_plan_name,
                                                        selectYear === 1 ? yearlyPriceWithDiscount : elem?.subscription_plan_msrp_price,
                                                        elem?.id,
                                                        actionText,
                                                        elem?.subscription_plan_msrp_price,
                                                        yearlyPriceWithDiscount,
                                                        subscriptionPlanDiscount?.year,

                                                    ), setModalIsOpen(true))}
                                                    className="btn btn-outline-primary"
                                                    disabled={activeStatus ? true : false}
                                                >
                                                    {actionText}
                                                </button>
                                                <div className="sub-plans-list-head">
                                                    {elem?.modules?.map((ele) => (
                                                        <div className="splh-inner" key={ele?.module_name}>
                                                            <div className="font-16 hd-subtitle">{ele?.module_name}</div>
                                                            <div className="tooltipp">
                                                                <span className="tooltipptext">{ele?.module_description}</span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.3333 13.3337V10.0003M10.3333 6.66699H10.3417M18.6667 10.0003C18.6667 14.6027 14.9357 18.3337 10.3333 18.3337C5.73096 18.3337 2 14.6027 2 10.0003C2 5.39795 5.73096 1.66699 10.3333 1.66699C14.9357 1.66699 18.6667 5.39795 18.6667 10.0003Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {elem?.duration_type === 'year' &&
                                            <div className="sub-plans-inner" key={elem?.id}>
                                                {activeStatus ? <span className="badge text-bg-success active-plan">Active</span> : ''}
                                                <div className="font-20 hd-title">
                                                    {elem?.subscription_plan_name}{i === 1 ? <span className="badge text-bg-success">Most popular</span> : ''}
                                                </div>
                                                <div className="hd-subtitle font-16">{elem?.subscription_plan_desc}</div>
                                                <div className="font-36 hd-title">
                                                    {selectYear === 1 ? formattedCurrency(yearlyPriceWithDiscount) : formattedCurrency(monthlyPrice)}
                                                    <span className="month-text">/{selectYear === 1 ? 'Yr' : 'Mo'}</span>
                                                </div>
                                                <button
                                                    onClick={() => (choose_plan(
                                                        elem?.subscription_plan_name,
                                                        selectYear === 1 ? yearlyPriceWithDiscount : elem?.subscription_plan_msrp_price,
                                                        elem?.id,
                                                        actionText,
                                                        elem?.subscription_plan_msrp_price,
                                                        yearlyPriceWithDiscount,
                                                        subscriptionPlanDiscount?.year,

                                                    ), setModalIsOpen(true))}
                                                    className="btn btn-outline-primary"
                                                    disabled={activeStatus ? true : false}
                                                >
                                                    {actionText}
                                                </button>
                                                <div className="sub-plans-list-head">
                                                    {elem?.modules?.map((ele) => (
                                                        <div className="splh-inner" key={ele?.module_name}>
                                                            <div className="font-16 hd-subtitle">{ele?.module_name}</div>
                                                            <div className="tooltipp">
                                                                <span className="tooltipptext">{ele?.module_description}</span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.3333 13.3337V10.0003M10.3333 6.66699H10.3417M18.6667 10.0003C18.6667 14.6027 14.9357 18.3337 10.3333 18.3337C5.73096 18.3337 2 14.6027 2 10.0003C2 5.39795 5.73096 1.66699 10.3333 1.66699C14.9357 1.66699 18.6667 5.39795 18.6667 10.0003Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>

                        );
                    })}
                </div>
                <div className='cancel-link' onClick={() => setDeleteModal(true)}>
                    Cancel your subscription Plan
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
            </div>
            <Elements stripe={stripePromise}>
            <UpdateSubscription amount1={amount1} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>
            </Elements>
            <DeleteSubscription DeleteModal={DeleteModal} setDeleteModal={setDeleteModal} />
        </>
    )
}

export default AuthSubscription
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FilterAndSearch from "./FilterAndSearch";
import { Pagination } from '../../../Pagination';
import DataNotFound from './DataNotFound';
import { formatAMPM, monthDayYearFormat } from '../../../../components/Common/Common';
import { get_edit_listing_all_data } from '../../../../redux/services/AuthMar';

const AdministeredTab = ({ id, activeTab }) => {
    const dispatch = useDispatch();
    
    // State selectors for pagination and data
    const totalPages = useSelector(state => state?.AuthMar?.AllListingData?.totalNoPages);
    const AllListingData = useSelector(state => state?.AuthMar?.AllListingData?.marListData);
    
    // Local state for pagination and sorting
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState(true);
    const [listingData, setListingData] = useState([]);

    // Effect to fetch data when the component mounts or `id` changes
    useEffect(() => {
        if (id) {
            const data = {
                page: 1,
                listData: 10,
                mar_detail_id: id,
                type: activeTab.toLowerCase()
            };
            dispatch(get_edit_listing_all_data(data));
        }
    }, [id, dispatch, activeTab]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        const data = {
            page: pageNumber,
            listData: 10,
            mar_detail_id: id,
            type: activeTab.toLowerCase()
        };
        setCurrentPage(pageNumber);
        dispatch(get_edit_listing_all_data(data));
    };

    // Toggle sorting order
    const handleSort = () => {
        setSortType(!sortType);
    };

    // Effect to sort the listing data whenever it or sortType changes
    useEffect(() => {
        if (AllListingData && AllListingData.length > 0) {
            const sortedData = [...AllListingData].sort((a, b) => {
                const dateTimeA = new Date(`${a.administer_date.split('-').reverse().join('-')}T${a.administer_time}`);
                const dateTimeB = new Date(`${b.administer_date.split('-').reverse().join('-')}T${b.administer_time}`);
                
                return sortType ? dateTimeA - dateTimeB : dateTimeB - dateTimeA; // Ascending or descending
            });
            setListingData(sortedData);
        } else {
            setListingData([]);
        }
    }, [sortType, AllListingData]);

    return (
        <div className="tab-pane fade vit_info_data mar_pending show active">
            {/* <FilterAndSearch /> Uncomment if needed */}
            <div className="col-md-12 col-12">
                <div className="table-sec">
                    <div className="table-responsive">
                        {listingData && listingData.length === 0 ? (
                            <DataNotFound activeTab={activeTab} />
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Last Administered Date
                                            <a onClick={handleSort}>
                                                {sortType ? (
                                                    <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path
                                                            d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                            stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : (
                                                    <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 10.6667V1.33337M6 1.33337L10.6667 6.00004M6 1.33337L1.33333 6.00004"
                                                            stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                )}
                                            </a>
                                        </th>
                                        <th scope="col">Route</th>
                                        <th scope="col">Frequency</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col" className="edit_mar_decs">Administer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listingData.map((item) => {
                                        // Extract and format date and time for display
                                        let dateTime = item?.checkbox_date_time ? item.checkbox_date_time.split(' ') : [];
                                        let date = dateTime.length > 0 ? monthDayYearFormat(dateTime[0]) : '';
                                        let time = dateTime.length > 1 ? formatAMPM(dateTime[1]) : '';

                                        return (
                                            <tr key={item.id}>
                                                <td>{date || ''}<span className="d-block">{time || ''}</span></td>
                                                <td>{item?.masterRoute?.name || ''}</td>
                                                <td className="lower">{item?.frequency || ''} x Daily</td>
                                                <td>{item?.give_amount_quantity || ''} {item?.give_amount_quantity > 1 ? 'Tablets': 'Tablet'}</td>
                                                <td className="edit_mar_decs"> 
                                                    <input className="form-check-input" type="checkbox" 
                                                        checked={item.administer_status === 'administered'} 
                                                        disabled 
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="5">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdministeredTab;

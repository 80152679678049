import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import UrlBar from "./UrlBar";
import { NotificationTimeFormate } from '../../../components/Common/Common'
import { getNotificationList, readMsgUpdate } from '../../../redux/services/AuthNotification'
import ResultNotFound from './ResultNotFound'
import { MemberSvg, HomeSvg, AppointmentSvg, TaskSvg, RoleSvg, CommunicationSvg, MARSvg } from '../../../Images/NotificationSvg';



const images = {
	Homes: <HomeSvg />,
	Member: <MemberSvg />,
	Communication: <CommunicationSvg/>,
	Appointments: <AppointmentSvg />,
	role: <RoleSvg />,
	Tasks: <TaskSvg />,
	MAR: <MARSvg/>
};

const Notification = () => {
	const dispatch = useDispatch();

	const NotificationList = useSelector(state => state?.AuthNotification?.NotificationList);
	const moduleData = useSelector((state) => state?.common?.moduleData);

	const [viewType, setViewType] = useState('all');
	const [menuItems, setMenuItems] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState('')

	useEffect(() => {
		dispatch(getNotificationList({ module_id: selectedFilter }))
	}, [selectedFilter])

	useEffect(() => {
		let menus = [...moduleData];
		
		  // Remove 'Users', 'Document', and 'Notification' modules
		  menus = menus.filter((item) => 
			item.module_name !== 'Users' && 
			item.module_name !== 'Documents' && 
			item.module_name !== 'Notifications'
		);
	  
		// Add the 'Roles' module
		menus.push({
		  id: 0,
		  module_name: 'Roles',
		  module_description: 'Roles Desc',
		});
	  
		setMenuItems(menus);
	  }, [moduleData]);
	  

	// Filter notifications based on 'all' or 'unread'
	const filteredNotifications = NotificationList && NotificationList.filter(Notification =>
		viewType === 'all' || (viewType === 'unread' && !Notification?.notificationAccess[0]?.msg_seen)
	);

	// Handle view type change (all / unread)
	const handleViewType = (type) => {
		setViewType(type);
	};

	// Handle click to mark notification as read
	const markAsRead = async (id) => {
		let res = await dispatch(readMsgUpdate(id))
		if (res.payload.status) {
			dispatch(getNotificationList({ module_id: selectedFilter }))
		}
	};

	const handleSelectFilter = (value) => {
		setSelectedFilter(value)
	}

	return (
		<div id="layoutSidenav_content">
			<main>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-12">
							<div className="right-main-sec appointment_dash_sec">
								<UrlBar />
								<div className="right-main-header adminstrat_btn_main">
									<h2>Notifications</h2>
								</div>
								<div className="row">
									<div className="col-md-12 col-12">
										<div className="notification_main">
										<div className="row">
										<div className="col-md-6 col-6">
										<nav>
											<div className="nav nav-tabs" id="nav-tab" role="tablist">
												<button
													className={`nav-link ${viewType === 'all' ? 'active' : ''}`}
													onClick={() => handleViewType('all')}
												>
													All
												</button>
												<button
													className={`nav-link ${viewType === 'unread' ? 'active' : ''}`}
													onClick={() => handleViewType('unread')}
												>
													Unread <span className="unread_msg">{NotificationList && NotificationList.filter(n => !n?.notificationAccess[0]?.msg_seen).length}</span>
												</button>
												
											</div>
										</nav>
										</div>
										<div className="col-md-6 col-6 notification_drop">
										<div className="notificationDropdown">
													<select className="form-select" aria-label="Default select example" value={selectedFilter} onChange={(e) => handleSelectFilter(e.target.value)}>
														<option value="">All</option>
														{menuItems && menuItems.map((item) => <option value={item?.id}>{item?.module_name}</option>)}
													</select>
												</div>
										</div>
										</div>
										</div>
										

										<div className="tab-content tabs_mem" id="nav-tabContent">
											<div className="tab-pane fade show active">
												{filteredNotifications && filteredNotifications.length > 0 ?
													<div className="notifivation_member">
														{filteredNotifications.map(notification => {															
															return (
																<li className={`${notification?.notificationAccess[0]?.msg_seen ? '' : 'unread_mag_bc'}`} key={notification?.id} onClick={() => markAsRead(notification?.notificationAccess[0]?.id)}>
																	<div className="notifivation_member_profile">
																		{images[notification?.module ? notification?.module?.module_name : 'role']}
																		{/* {notification?.notificationAccess[0]?.msg_seen ? (
																			<svg width="22" height="28" viewBox="0 0 22 28" fill="none"
																				xmlns="http://www.w3.org/2000/svg">
																				<path
																					d="M10.9957 18.2328C5.24507 18.2328 0.333069 19.1395 0.333069 22.7661C0.333069 26.3941 5.2144 27.3328 10.9957 27.3328C16.7464 27.3328 21.6584 26.4275 21.6584 22.7995C21.6584 19.1715 16.7784 18.2328 10.9957 18.2328Z"
																					fill="#2342BF" />
																				<path opacity="0.4"
																					d="M10.9958 14.7783C14.9131 14.7783 18.0518 11.6383 18.0518 7.72226C18.0518 3.80626 14.9131 0.66626 10.9958 0.66626C7.07976 0.66626 3.93976 3.80626 3.93976 7.72226C3.93976 11.6383 7.07976 14.7783 10.9958 14.7783Z"
																					fill="#2342BF" />
																			</svg>
																		) : (
																			<img src={Ellipse} alt="" />
																		)} */}
																	</div>
																	<div className="notifivation_member_contant">
																		<div className="font-14 hd-subtitle">
																			{notification?.module === null ? 'Role' : notification?.module?.module_name} <div className="gray_color"></div>{NotificationTimeFormate(notification?.created_date)}
																		</div>
																		<p className="font-18 f_weight">{notification?.message}</p>
																		{notification?.description && <p>{notification.description.length > 50
																			? notification.description.substring(0, 50) + '...'
																			: notification.description}</p>}
																	</div>
																</li>
															)
														})}
													</div>
													:
													<ResultNotFound />
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}

export default Notification;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";

//notification-list
export const getNotificationList = createAsyncThunk(
   "auth/notification-list",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');        
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/notification-list?module_id=${data?.module_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if(error?.response?.data?.message !== 'jwt expired'){
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }else{
                localStorage.clear()
            }
        }
    }
);
//read-msg-update
export const readMsgUpdate = createAsyncThunk(
    "auth/read-msg-update",
     async (id, thunkAPI) => {
         const state = thunkAPI.getState();
         const token = state?.auth?.token;
         const refreshToken = localStorage.getItem('refreshToken');
         try {
             let config = {
                 method: 'get',
                 url: `${HTTPURL}api/read-msg-update?id=${id}`,
                 headers: {
                     'Authorization': `Bearer ${token}`,
                     'refreshToken': `${refreshToken}`,
                 },
             };
             const response = await axios.request(config)
             return response.data;
         } catch (error) {
             customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
         }
     }
 );
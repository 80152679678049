import { createSlice } from "@reduxjs/toolkit";
import {
    getAuthSettingDetail, Update_general_detail, Update_security_detail, Update_organization_detail,
    Update_billing_detail, getModulePermission, getPlanFeatureActive, Create_role_permission, Delete_role_permission,
    edit_role_permission, update_role_permission,update_subscription_plan,Cancel_Subscription_plan,get_subscripton_cancel_reasons, get_customer_all_cards
} from "../services/AuthSettings";
const initialState = {
    isLoading: false,
    error: null,
    settingInfo: [],
    ModulePermissions: [],
    EditRoleData: [],
    SubscriptionCancelReasons: [],
    CustomerAllCard:[]
}

const AuthSettings = createSlice({
    name: 'AuthSetting',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // ==========Settings Information============== //
        builder.addCase(getAuthSettingDetail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getAuthSettingDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.settingInfo = action?.payload?.data
        });
        builder.addCase(getAuthSettingDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Update general detail ============== //
        builder.addCase(Update_general_detail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Update_general_detail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Update_general_detail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Update security detail ============== //
        builder.addCase(Update_security_detail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Update_security_detail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Update_security_detail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Update organization detail ============== //
        builder.addCase(Update_organization_detail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Update_organization_detail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Update_organization_detail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Update billing detail ============== //
        builder.addCase(Update_billing_detail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Update_billing_detail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Update_billing_detail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get module permissions============== //
        builder.addCase(getModulePermission.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getModulePermission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ModulePermissions = action?.payload?.data
        });
        builder.addCase(getModulePermission.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ========== Subscription plan detail============== //
        builder.addCase(getPlanFeatureActive.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getPlanFeatureActive.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.subscriptionPlanList = action?.payload?.data
        });
        builder.addCase(getPlanFeatureActive.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Create Role and permission ============== //
        builder.addCase(Create_role_permission.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Create_role_permission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Create_role_permission.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit role data============== //
        builder.addCase(edit_role_permission.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_role_permission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditRoleData = action?.payload?.data
        });
        builder.addCase(edit_role_permission.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Delete Role and permission ============== //
         builder.addCase(Delete_role_permission.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Delete_role_permission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Delete_role_permission.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
            // ==========update Role and permission ============== //
            builder.addCase(update_role_permission.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            });
            builder.addCase(update_role_permission.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
            });
            builder.addCase(update_role_permission.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.payload;
            });
               // ==========Update subscription plne ============== //
        builder.addCase(update_subscription_plan.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(update_subscription_plan.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(update_subscription_plan.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Cancel subscription plan ============== //
         builder.addCase(Cancel_Subscription_plan.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Cancel_Subscription_plan.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Cancel_Subscription_plan.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
             // ==========get subscription cancel reasons============== //
             builder.addCase(get_subscripton_cancel_reasons.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            });
            builder.addCase(get_subscripton_cancel_reasons.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.SubscriptionCancelReasons = action?.payload?.data
            });
            builder.addCase(get_subscripton_cancel_reasons.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.payload;
            });
            // ==========get_customer_all_cards============== //
            builder.addCase(get_customer_all_cards.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            });
            builder.addCase(get_customer_all_cards.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.CustomerAllCard = action?.payload?.data
            });
            builder.addCase(get_customer_all_cards.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.payload;
            });
    }
})

export default AuthSettings?.reducer;

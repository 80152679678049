import React from "react";

const DeleteTask = ({setDeleteTask, onDeleteSubmit}) => {

    return(
        <div className="modal fade new_medi_modal show">
        <div className="modal-dialog msg task_delete_popup">
            <div className="modal-content ">
                <div className="modal-header">
                    <h4>Delete Task</h4>
                    <button type="button" className="btn-close" onClick={()=> setDeleteTask('')}></button>
                </div>
                <div className="modal-body">
                <div className="delete_box">
                    <p className="font-18 hd-subtitle">Are you sure you want to delete this Task?</p>
    
                    <p className="font-14 hd-subtitle ">Please note that this action cannot be undo.</p>
    
                    <div className="cancel_delete">
                        <button type="button" className="btn btn-light" onClick={()=> setDeleteTask('')}>Cancel</button> &nbsp;
                        <button type="button" className="btn btn-outline-danger bg-danger text-white" onClick={()=> onDeleteSubmit()}>Yes Delete</button>
                    </div>
                </div>
                </div>
            </div>
    
        </div>
      </div>
    )
}
export default DeleteTask
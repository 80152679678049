import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'
import {get_prematative_service_list } from '../../../../../../redux/services/AuthMembers';

const MedicalSuppliesForm = ({onHandleBackStep, onSubmit, isGoBack}) => {
  const dispatch = useDispatch();

  const [checkedServices, setCheckedServices] = useState([])

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.preventiveScreeningInfo);
  const prematativeServiceList = useSelector(state => state?.AuthMember?.prematativeServiceList);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(()=>{
    dispatch(get_prematative_service_list())
  },[])

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'Have you gone to the Emergency Room for care and were not admitted to the hospital (including 23 hours\nobservation)?':
            setValue('hospital_emergency_room_for_care', item?.value);
            break
          case 'Do you have any surgeries/procedures scheduled for the next six months?':
            setValue('surgery_for_next_six_months', item?.value);
            break
            case 'If SMI determined, has the member been assessed/referred for Special Assistance from the Office of Human\nRights (OHR)?' :
              setValue('office_of_human_rights_notes', item.value)
        }
      })
    } else {
      setValue('other_services', allDddPcsp?.other_services);
      setValue('notes', allDddPcsp?.notes);
      setValue('flue_vaccination', allDddPcsp?.flue_vaccination);
      setValue('recorder_date_fluevacc', allDddPcsp?.recorder_date_fluevacc);
      setValue('pneumonia_vaccination', allDddPcsp?.pneumonia_vaccination);
      setValue('recorder_date_pneumonia', allDddPcsp?.recorder_date_pneumonia);
      setValue('overnight_hospital_stay', allDddPcsp?.overnight_hospital_stay);
      setValue('surgery_for_next_six_months', allDddPcsp?.surgery_for_next_six_months);
      setValue('whenlast_child_epsdt_visit', allDddPcsp?.whenlast_child_epsdt_visit);
      setValue('accessed_member_for_smi_eligibility', allDddPcsp?.accessed_member_for_smi_eligibility);
      setValue('smi_designation_notes', allDddPcsp?.smi_designation_notes);
      setValue('office_of_human_rights_notes', allDddPcsp?.office_of_human_rights_notes);
      setValue('hospital_emergency_room_for_care', allDddPcsp?.hospital_emergency_room_for_care);

      const allCheckedServices = allDddPcsp?.memberdddpcspPrefStrengthsPreventiveScreeningInfoServices;
      if(allCheckedServices && allCheckedServices.length > 0) {
        let Ids = []
        allCheckedServices.map((item) =>{
          Ids.push(item.preventive_option_id)
        })
        setCheckedServices(Ids)
      }
    }
  }, [allDddPcsp])

  const handleCheckboxChange = (id, type) => {
    let ids = [...checkedServices]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedServices(ids);
    setValue('screeningServices', ids);
  };


    return(
        <>
        <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="alert alert-light sap-alert-heading">
              <div className="hd-title font-24">Medical supplies</div>
            </div>
            <div className="member_vital_basic_title">
              <h4>Preventative screening services</h4>
            </div>
            <div className="col-12">
              <div className="grade_content">
                <h2>Have you had any of the following preventive services in the last year?</h2>
                <div className="date-slot activity-sec">
                  <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  {prematativeServiceList && prematativeServiceList.length > 0 && prematativeServiceList.map((item, index)=>{
                        return(
                          <li className="list-group-item">
                        <input 
                        type="checkbox" 
                        className="btn-check" 
                        id={`btncheck${index}`} 
                        autocomplete="off" 
                        value={item.id}
                        onChange={() => handleCheckboxChange(item?.id, item.type)}
                        checked = {checkedServices.includes(item.id)}
                        />
                        <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                      </li>
                        )
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Other services (only shows if other is selected)*</label>
              <input
              type="input"
              className="form-control"
              placeholder="Enter Other services"
              {...register(`other_services`, { required: "Other services is required!" })}
              maxLength={20}
            />
            {errors?.other_services && (
              <span className="error">{errors.other_services.message}</span>
            )}
            </div>
            <div className="col-12">
              <label for="" className="form-label">Notes*</label>
              <div className="mb-3">
              <textarea className="form-control" placeholder="Enter notes" {...register("notes", { required: "true" })} maxLength={150} />
              {errors.notes && errors.notes.type === "required" && (<span className="error">Notes is required!</span>)}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Flu vaccination*</label>
              <select
              className="form-select"
              aria-label="Default select example"
              {...register(`flue_vaccination`, { required: "Flu vaccination is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.flue_vaccination && (
              <span className="error">{errors.flue_vaccination.message}</span>
            )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Estimated date recorded*</label>
              <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`recorder_date_fluevacc`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.recorder_date_fluevacc && (
              <span className="error">{errors.recorder_date_fluevacc.message}</span>
            )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Pneumonia vaccination*</label>
              <select
              className="form-select"
              aria-label="Default select example"
              {...register(`pneumonia_vaccination`, { required: "Pneumonia vaccination is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.pneumonia_vaccination && (
              <span className="error">{errors.pneumonia_vaccination.message}</span>
            )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Estimated date recorded*</label>
              <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`recorder_date_pneumonia`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.recorder_date_pneumonia && (
              <span className="error">{errors.recorder_date_pneumonia.message}</span>
            )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Have you stayed overnight as a patient in a hospital?*</label>
              <select
              className="form-select"
              aria-label="Default select example"
              {...register(`overnight_hospital_stay`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.overnight_hospital_stay && (
              <span className="error">{errors.overnight_hospital_stay.message}</span>
            )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Have you gone to the Emergency Room for care and were not admitted to the hospital (including 23 hours observation)? If yes, describe frequency and circumstances*</label>
                <textarea className="form-control" placeholder="Enter notes" {...register("hospital_emergency_room_for_care", { required: "true" })} maxLength={150} />
                {errors.hospital_emergency_room_for_care && errors.hospital_emergency_room_for_care.type === "required" && (<span className="error">Notes is required!</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Do you have any surgeries/procedures scheduled for the next six months?
                If yes, describe*</label>
                <textarea className="form-control" placeholder="Enter notes" {...register("surgery_for_next_six_months", { required: "true" })} maxLength={150} />
                {errors.surgery_for_next_six_months && errors.surgery_for_next_six_months.type === "required" && (<span className="error">Notes is required!</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">If a child, when was the child’s last well visit (EPSDT visit)*</label>
              <input
              type="input"
              className="form-control"
              placeholder="Child visit"
              {...register(`whenlast_child_epsdt_visit`, { required: "This is required!" })}
              maxLength={20}
            />
            {errors?.whenlast_child_epsdt_visit && (
              <span className="error">{errors.whenlast_child_epsdt_visit.message}</span>
            )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Have you (member) been assessed for the need to receive an SMI Eligibility Determination?*</label>
                <select
              className="form-select"
              aria-label="Default select example"
              {...register(`accessed_member_for_smi_eligibility`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.accessed_member_for_smi_eligibility && (
              <span className="error">{errors.accessed_member_for_smi_eligibility.message}</span>
            )}
              <p className="mt-2">N/A for members already determined SMI or for whom the member/HCDM has declined the option for SMI designation</p>
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Notes*</label>
              <textarea className="form-control" placeholder="Enter notes" {...register("smi_designation_notes", { required: "true" })} maxLength={150} />
                {errors.smi_designation_notes && errors.smi_designation_notes.type === "required" && (<span className="error">Notes is required!</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">If SMI determined, has the member been assessed/referred for Special Assistance from the Office of Human Rights (OHR)? If no, explain why*</label>
              <textarea className="form-control" placeholder="Enter notes" {...register("office_of_human_rights_notes", { required: "true" })} maxLength={150} />
                {errors.office_of_human_rights_notes && errors.office_of_human_rights_notes.type === "required" && (<span className="error">Notes is required!</span>)}
            </div>
            <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
        </div>
        </>
    )
}
export default MedicalSuppliesForm
import React from "react";
import Image404 from '../Images/404-image.png'

const PageNotFound = () => {

    return(
        <div className="page-not-found">
        <div className="container-fluid">
            <div className="page-interface text-center">
                <img src={Image404} alt=""/>
                <div className="page-detail">
                    <h1>404 - PAGE NOT FOUND</h1>
                    <div className="about-para">
                        <p>The page you are looking for might have been removed, its name changed, or it may be temporarily unavailable.</p>
                    </div>
                    <a href="/" className="bg-primary">GO TO HOMEPAGE</a>
                </div>
            </div>
        </div>
    </div>
    )
}
export default PageNotFound
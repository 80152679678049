import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import UrlBar from './UrlBar'
import { reSetCommunicationById } from '../../../redux/features/AuthCommunicationSlice'
import { export_all_communication } from '../../../redux/services/AuthCommunication'
import { ExportToExcel } from './ExportFile'

const Header = ({ handleSearchBar, search, onModalToggle }) => {
    const dispatch = useDispatch()

    const userPermissions = useSelector((state) => state?.common?.userPermissions);
    //export xsl file
    const exportToExcel = async () => {
        const response = await dispatch(export_all_communication());
        if (response?.payload?.status == true) {
            ExportToExcel(response?.payload?.data, 'Communication')
        }
    }
    const handleAddNew = () => {
        onModalToggle(true)
        dispatch(reSetCommunicationById())
    }

    return (
        <>
            <UrlBar />
            <div className="right-main-header adminstrat_btn_main">
                <h2>Communication</h2>
                <div className="adminstrat_btn">
                    <button type="button" className="btn btn-light" onClick={() => exportToExcel()}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6667 1.8916V5.33372C11.6667 5.80043 11.6667 6.03378 11.7575 6.21204C11.8374 6.36885 11.9649 6.49633 12.1217 6.57622C12.3 6.66705 12.5333 6.66705 13 6.66705H16.4422M7.50004 12.5003L10 15.0003M10 15.0003L12.5 12.5003M10 15.0003L10 10.0003M11.6667 1.66699H7.33337C5.93324 1.66699 5.23318 1.66699 4.6984 1.93948C4.22799 2.17916 3.84554 2.56161 3.60586 3.03202C3.33337 3.5668 3.33337 4.26686 3.33337 5.66699V14.3337C3.33337 15.7338 3.33337 16.4339 3.60586 16.9686C3.84554 17.439 4.22799 17.8215 4.6984 18.0612C5.23318 18.3337 5.93324 18.3337 7.33337 18.3337H12.6667C14.0668 18.3337 14.7669 18.3337 15.3017 18.0612C15.7721 17.8215 16.1545 17.439 16.3942 16.9686C16.6667 16.4339 16.6667 15.7338 16.6667 14.3337V6.66699L11.6667 1.66699Z"
                                stroke="#344054" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                        Export
                    </button>

                    {userPermissions && userPermissions.includes('Add') &&
                        <button type="button" className="btn btn-primary" onClick={() => handleAddNew()}>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white"
                                    stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                            New Com Log
                        </button>
                    }
                </div>
            </div>
            <div className="table-btn-sec">
                <div className="form-group searchbar"><input type="text" name="" id="" className="form-control" placeholder="Search" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </>
    )
}
export default Header
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";
import { clearStateAndPersistedData } from '../store';




const clearData = () => {
    localStorage.clear();
    clearStateAndPersistedData();
    window.location.href('/login');
}
export const getAuthSettingDetail = createAsyncThunk(
    "auth/owner-info",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-settings-information`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }

            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


export const Update_general_detail = createAsyncThunk(
    "auth/update-general-detail",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-general-settings`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


export const Update_security_detail = createAsyncThunk(
    "auth/update-security-settings",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-security-settings`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const Update_organization_detail = createAsyncThunk(
    "auth/update-organization-settings",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-organization-settings`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const Update_billing_detail = createAsyncThunk(
    "auth/update-billing-plan",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-billing-plan`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const getModulePermission = createAsyncThunk(
    "auth/get-module-permission",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-module-permission`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const getPlanFeatureActive = createAsyncThunk(
    "auth/get-plan-feature-active",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-plan-feature-active`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// update licence
export const update_subscription_plan = createAsyncThunk(
    "auth/update-selected-subscription-plan",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-selected-subscription-plan`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//setting role and permission
//add role
export const Create_role_permission = createAsyncThunk(
    "auth/create-role-permission",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/create-role-permission`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const Delete_role_permission = createAsyncThunk(
    "auth/role-permission-destroy",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/role-permission-destroy?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const edit_role_permission = createAsyncThunk(
    "auth/role-permission-edit",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/role-permission-edit?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const update_role_permission = createAsyncThunk(
    "auth/role-permission-update",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/role-permission-update`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// cancel subscription plan
export const Cancel_Subscription_plan = createAsyncThunk(
    "auth/subscription-plan-cancel",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/subscription-plan-cancel`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// get subscription cancel resons
export const get_subscripton_cancel_reasons = createAsyncThunk(
    "auth/reason-list",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/reason-list`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if (error?.response?.data && error?.response?.data?.message === "There is no user!") {
                clearData()
            }
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// get customer-all-cards
export const get_customer_all_cards = createAsyncThunk(
    "auth/customer-all-cards",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/customer-all-cards`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import Select from "react-select";
import { Controller, useForm } from 'react-hook-form';

import { gat_all_homes, Deactivate_all_homes, Delete_homes, home_users_destroy_list, homes_list_users_not_assign, homes_list_member_not_assign, user_transafer_to_home, member_transafer_to_home, home_member_destroy_list, get_home_assign_users, get_home_assign_members } from '../../../redux/services/AuthHomes';

const EditHomeDelete = ({ id, modalIsOpen, setModalIsOpen, selectedUsers, type, setSelectedUsers, search, setDeleteSingle, deleteSingle }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const homeid = useParams();
    const { control } = useForm();
    Modal.setAppElement('#root');

    const GetHomeAssignUsers = useSelector((state) => state?.AuthHomes?.GetHomeAssignUsers?.sendData
    );
    const GetHomeAssignMembers = useSelector((state) => state?.AuthHomes?.GetHomeAssignMembers?.homeMembers);

    const [cannotDeactivate, setCannotDeactivate] = useState(false);
    const [showConnections, setShowConnections] = useState(false);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const [selectedUsersNoHome, setSelectedUsersNoHome] = useState([]);
    const [selectedMemberNoHome, setSelectedMemberNoHome] = useState([]);
    const [userNotAssignToHome, setUserNotAssignToHome] = useState([]);
    const [memberNotAssignToHome, setMemberNotAssignToHome] = useState([]);
    const [selectedMember, setSelectedMember] = useState([])
    const [memberData, setMemberData] = useState([]);
    const [data, setData] = useState([]);

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '750px',
            // width: '100%',
            background: '#fff',
            borderRadius: '8px',
            padding: '20px',
            // height: `${showConnections ? '60%' : '34%'}`,
            zIndex: '99',
            transition: 'all 1s ease-in-out'
        }
    };

    const getData = () => {
        const UserPayload = {
            role: '',
            status: '',
            page: 1,
            listData: 10,
            home: '',
            searchBar: '',
            id: homeid?.id ? homeid?.id : deleteSingle && deleteSingle?.home_table_id 
        }
        const MemberPayload = {
            role: '',
            status: '',
            page: 1,
            listData: 10,
            home: '',
            searchBar: '',
            id: homeid?.id ? homeid?.id : deleteSingle && deleteSingle?.home_table_id 
        }
        
        dispatch(get_home_assign_users(UserPayload));
        dispatch(get_home_assign_members(MemberPayload));
    }
    useEffect(() => {
        getData()
    }, [modalIsOpen])

    useEffect(() => {
        if (GetHomeAssignUsers) {
            setData(GetHomeAssignUsers)
        }
        if (GetHomeAssignMembers) {
            setMemberData(GetHomeAssignMembers)
        }
    }, [GetHomeAssignUsers, GetHomeAssignMembers])

    const handleUserChange = async (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
        setSelectedUsersNoHome([])
        const payload = {
            user_id: selectedSubOptions?.value,
            id: deleteSingle?.home_table_id
        }
        const response = await dispatch(homes_list_users_not_assign(payload));
        if (response?.payload?.status == true) {
            setUserNotAssignToHome(response?.payload?.data)
        }
    };
    const handleUserWithNoHome = (selectedSubOptions) => {
        setSelectedUsersNoHome(selectedSubOptions);
    };
    const handleMemberWithNoHome = (selectedSubOptions) => {
        setSelectedMemberNoHome(selectedSubOptions);
    };
    const handleMemberChange = async (selectedSubOptions) => {
        setSelectedMember(selectedSubOptions);
        setSelectedMemberNoHome([])
        const payload = {
            member_id: selectedSubOptions?.value,
            id: deleteSingle?.home_table_id
        }
        const response = await dispatch(homes_list_member_not_assign(payload));
        if (response?.payload?.status == true) {
            setMemberNotAssignToHome(response?.payload?.data)
        }
    };
    const OnClose = () => {
        setModalIsOpen(false);
        if (type == 'Deactivate_single') {
            setCannotDeactivate(false)
            setDeleteSingle({ modal: false, staff: '', home_table_id: '' });
            setShowConnections(false);
            setSelectedSubOptions([]);
            setUserNotAssignToHome([]);
            setSelectedUsersNoHome([])
        }
    }
    const TransferHomeToUser = async () => {
        const payload = {
            home_table_id: selectedUsersNoHome?.value,
            user_id: selectedSubOptions?.value,
            from_home_id: deleteSingle?.home_table_id
        }
        const response = await dispatch(user_transafer_to_home(payload))
        if (response?.payload?.status == true) {
            dispatch(gat_all_homes(search))
            setSelectedSubOptions([]);
            setSelectedUsersNoHome([]);
            setUserNotAssignToHome([]);
            getData()
        }
    }
    const TransferHomeToMember = async () => {
        const payload = {
            home_table_id: selectedMemberNoHome?.value,
            member_id: selectedMember?.value,
            from_home_id: deleteSingle?.home_table_id
        }
        const response = await dispatch(member_transafer_to_home(payload))
        if (response?.payload?.status == true) {
            dispatch(gat_all_homes(search))
            setSelectedSubOptions([]);
            setSelectedMember([])
            setSelectedMemberNoHome([]);
            setMemberNotAssignToHome([]);
            getData()
        }
    }
    const onSubmit = async () => {
        if (id && type !== 'Deactivate_single') {
            dispatch(Delete_homes(id?.id));
            setModalIsOpen(false);
            navigate('/auth/homes');
        } else if (type == 'Deactivate_All') {
            const payload = {
                selectedHomes: selectedUsers
            };
            await dispatch(Deactivate_all_homes(payload));
            setModalIsOpen(false);
            setSelectedUsers('')
            dispatch(gat_all_homes(search))
            //  navigate('/auth/homes');
        }
        else if (type == 'Deactivate_single') {            
            if ((GetHomeAssignUsers?.length > 0) || (GetHomeAssignMembers?.length > 0)) {
                setCannotDeactivate(true)
            } else {
                const payload = {
                    home_table_id: deleteSingle?.home_table_id || id?.id,
                    count: data?.length === 0 ? 0 : ''
                }
                const response = await dispatch(home_users_destroy_list(payload));
                if (response?.payload?.status == true) {
                    setModalIsOpen(false);
                    dispatch(gat_all_homes(search));
                    setCannotDeactivate(false);
                    navigate('/auth/homes');
                }
            }
        }
        else {
            setModalIsOpen(false);
        }
    }
    //multi select user

    const userListData = data?.map((item) => ({
        id: item?.id,
        value: item?.id,
        label: (
            <span>{`${item?.first_name} ${item?.last_name}`}</span>
        )
    }));
    const userListWithNoHomeAssign = userNotAssignToHome?.map((item) => ({
        value: item?.id,
        label: (
            <span>{`${item?.home_name}`}</span>
        )
    }));

    const memberListWithNoHomeAssign = memberNotAssignToHome?.map((item) => ({
        value: item?.id,
        label: (
            <span>{`${item?.home_name}`}</span>
        )
    }));

    const memberListData = memberData?.map((item) => ({
        id: item?.member_id,
        value: item?.member_id,
        label: (
            <span>{`${item?.first_name} ${item?.last_name}`}</span>
        )
    }));
    
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg communication_edit_modal">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => OnClose()}></button>
                    </div>
                    <div className="modal-body">
                        {
                            cannotDeactivate ?
                                <>
                                    <div className="font-18 hd-title">Cannot delete Home</div>
                                    <div className="hd-subtitle font-14">This home is currently assigned to a user(s) and/or member(s) and cannot be deactivated until all attached members/users are re-assigned to another home</div>
                                </>
                                :
                                <>
                                    <div className="font-18 hd-title">{type == "Deactivate_single" ? 'Delete' : 'Deactivate'} Home</div>
                                    <div className="hd-subtitle font-14">Are you sure you want to {type == "Deactivate_single" ? 'delete' : 'deactivate'} this home ?</div>
                                </>
                        }
                        {
                            showConnections ?
                                <>
                                    <Controller name="assignUser" control={control} defaultValue={[]} render={({ field }) => (
                                        <Select options={userListData} className="mb-3"
                                            placeholder={`Users (${userListData?.length || 0})`}
                                            isMulti={false}
                                            {...field}
                                            value={selectedSubOptions}
                                            onChange={(option) => (handleUserChange(option))}
                                        />
                                    )}
                                    />
                                    {
                                        userNotAssignToHome?.length > 0 ?
                                            <Controller name="notAssignUers" control={control} defaultValue={[]} render={({ field }) => (
                                                <Select options={userListWithNoHomeAssign} className="mb-3"
                                                    placeholder={`Homes (${userListWithNoHomeAssign?.length})`}
                                                    isMulti={false}
                                                    {...field}
                                                    value={selectedUsersNoHome}
                                                    onChange={(option) => handleUserWithNoHome(option)}
                                                />
                                            )}
                                            /> : ''
                                    }
                                    {selectedUsersNoHome?.value?.length !== undefined ?
                                        <button type="button" className="btn btn-primary mb-3 w-100" onClick={() => (selectedUsersNoHome?.value?.length !== undefined ? TransferHomeToUser() : setShowConnections(true))}>
                                            Transfer
                                        </button> : ''}

                                    <Controller name="Members" control={control} defaultValue={[]} render={({ field }) => (
                                        <Select options={memberListData} className="mb-3"
                                            placeholder={`Members (${memberListData?.length})`}
                                            isMulti={false}
                                            {...field}
                                            value={selectedMember}
                                            onChange={(option) => handleMemberChange(option)}
                                        />
                                    )}
                                    />
                                    {
                                        memberNotAssignToHome?.length > 0 ?
                                            <Controller name="notAssignUers" control={control} defaultValue={[]} render={({ field }) => (
                                                <Select options={memberListWithNoHomeAssign} className="mb-3"
                                                    placeholder={`Homes (${memberListWithNoHomeAssign?.length})`}
                                                    isMulti={false}
                                                    {...field}
                                                    value={selectedMemberNoHome}
                                                    onChange={(option) => handleMemberWithNoHome(option)}
                                                />
                                            )}
                                            /> : ''
                                    }
                                    {selectedMemberNoHome?.value?.length !== undefined ?
                                        <button type="button" className="btn btn-primary mb-3 w-100" onClick={() => (selectedMemberNoHome?.value?.length !== undefined ? TransferHomeToMember() : setShowConnections(true))}>
                                            Transfer
                                        </button> : ''}
                                </>
                                : ''
                        }
                        <div className="pop-action-btn">
                            <button type="button" className="btn btn-light" onClick={() => OnClose()}>Cancel</button>
                            {
                                cannotDeactivate ?
                                    <>
                                        {GetHomeAssignUsers?.length === 0 && GetHomeAssignMembers?.length === 0 ? 
                                        <button type="submit" className="btn btn-danger mx-2" onClick={() => (onSubmit())}>Delete</button>
                                         :
                                                <button type="button" className="btn btn-primary mx-2" onClick={() => (GetHomeAssignUsers?.length === 0 && GetHomeAssignMembers?.length === 0 ? TransferHomeToUser() : setShowConnections(true))}>
                                                    View Connections
                                                </button>}
                                    </>
                                    : <button type="submit" className="btn btn-danger mx-2" onClick={() => (type == 'Deactivate_single' && deleteSingle?.staff > 0 && data?.length > 0 ? (setCannotDeactivate(true), onSubmit()) : onSubmit())}>{type == "Deactivate_single" ? 'Delete' : 'Deactivate'}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}

export default EditHomeDelete
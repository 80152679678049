import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";

//get all active homes
export const Activate_homes = createAsyncThunk(
    "auth/get-active-homes",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-active-homes`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// add home
export const Add_Home = createAsyncThunk(
    "/api/add-home",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/add-home `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);

//get  All homes
export const gat_all_homes = createAsyncThunk(
    "auth/list-home",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const start_cap = `${SearchData?.max_cap ? SearchData?.max_cap?.start : ''}`;
        const end_cap = `${SearchData?.max_cap ? SearchData?.max_cap?.end : ''}`;
        const max_cap = start_cap && end_cap ? start_cap + ',' + end_cap : '';

        const c_start_cap = `${SearchData?.current_cap ? SearchData?.current_cap?.start : ''}`;
        const c_end_cap = `${SearchData?.current_cap ? SearchData?.current_cap?.end : ''}`;
        const current_cap = c_start_cap && c_end_cap ? c_start_cap + ',' + c_end_cap : '';

        const start_NoUSers = `${SearchData?.no_users ? SearchData?.no_users?.start : ''}`;
        const end_NoUSers = `${SearchData?.no_users ? SearchData?.no_users?.end : ''}`;
        const no_of_users = start_NoUSers && end_NoUSers ? start_NoUSers + ',' + end_NoUSers : '';
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/list-home?page=${SearchData?.page}&listData=${SearchData?.listData}&max_cap=${max_cap || ''}&current_cap=${current_cap || ''}&no_users=${no_of_users || ''}&searchQuery=${SearchData?.searchBar ? SearchData?.searchBar : ''}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// edit home
export const edit_home = createAsyncThunk(
    "auth/edit-home",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/edit-home?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// update home
export const update_home = createAsyncThunk(
    "auth/update-home",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-home`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//Activate home
export const Activate_home = createAsyncThunk(
    "auth/activate-home",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/activate-home?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//deactivate single home
export const Delete_homes = createAsyncThunk(
    "auth/destroy-home",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-home?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//deactivate multiple home
export const Deactivate_all_homes = createAsyncThunk(
    "auth/select-deactivate-homes",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/select-deactivate-homes`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//home assign user list 
export const get_home_assign_users = createAsyncThunk(
    "auth/home-assign-users-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/home-assign-users-list?home_table_id=${SearchData?.id}&page=${SearchData?.page}&listData=${SearchData?.listData}&is_active=${SearchData?.status}&role_id=${SearchData?.role}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

// users-without-assign-home
export const user_without_assign_home = createAsyncThunk(
    "auth/users-without-assign-home",
    async (homeId, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/users-without-assign-home?id=${homeId}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

//deactivate single home
export const destroy_user_from_home = createAsyncThunk(
    "auth/destroy-user-from-home",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-user-from-home?home_table_id=${data?.home_table_id}&user_id=${data?.user_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// home-add-users (pop up)
export const home_add_users = createAsyncThunk(
    "auth/home-add-users",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/home-add-users`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)


//home-users-destroy-list
export const home_users_destroy_list = createAsyncThunk(
    "auth/home-users-destroy-list",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/home-users-destroy-list?home_table_id=${data?.home_table_id}&count=${data?.count}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

//homes_list_users_not_assign
export const homes_list_users_not_assign = createAsyncThunk(
    "auth/homes-list-users-not-assign",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/homes-list-users-not-assign?home_table_id=${data?.id}&user_id=${data?.user_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });


//homes_list_member_not_assign
export const homes_list_member_not_assign = createAsyncThunk(
    "auth/homes-list-members-not-assign",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/homes-list-members-not-assign?home_table_id=${data?.id}&member_id=${data?.member_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },

            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

//home-member-destroy-list
export const home_member_destroy_list = createAsyncThunk(
    "auth/home-members-destroy-list",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/home-members-destroy-list?home_table_id=${data?.home_table_id}&count=${data?.count}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });
// //home-users-destroy-list
// export const user_transafer_to_home = createAsyncThunk(
//     "auth/user-transafer-to-home",
//     async (data, thunkAPI) => {
//         const state = thunkAPI.getState();
//         const token = state?.auth?.token;
//         const refreshToken = localStorage.getItem('refreshToken');
//         try {
//             let config = {
//                 method: 'POST',
//                 url: `${HTTPURL}api/user-transafer-to-home`,
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'refreshToken': `${refreshToken}`,
//                 },
//                 body:data
//             };
//             const response = await axios.request(config)
//             return response.data;
//         } catch (error) {
//             customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
//         }
//     });


    
//user_transafer_to_home
export const user_transafer_to_home = createAsyncThunk(
    "auth/user-transafer-to-home",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/user-transafer-to-home`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)

//member_transafer_to_home
export const member_transafer_to_home = createAsyncThunk(
    "auth/member-transafer-to-home",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/member-transafer-to-home`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)

//export-excel-file
export const export_excel_file_home = createAsyncThunk(
    "auth/export-excel-file",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/export-excel-file`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

    //home-check-email-phone-exist
export const CheckEmailPhone_home = createAsyncThunk(
    "auth/check-existing-home-validation",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/check-existing-home-validation`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status==true) {
                // customAlert(res?.data?.message,'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

//home assign member list 
export const get_home_assign_members = createAsyncThunk(
    "auth/home-assign-members-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/home-assign-members-list?home_table_id=${SearchData?.id}&listData=${SearchData?.listData}&page=${SearchData?.page}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });


    //get all active members
export const Activate_members = createAsyncThunk(
    "auth/get-active-member",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-active-member`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


//deactivate single home
export const destroy_member_from_home = createAsyncThunk(
    "auth/destroy-member-from-home",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-member-from-home?home_table_id=${data?.home_table_id}&member_id=${data?.member_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


// member-without-assign-home
export const member_without_assign_home = createAsyncThunk(
    "auth/members-without-assign-home",
    async (homeId, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/members-without-assign-home?id=${homeId}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });

// home-add-members (pop up)
export const home_add_members = createAsyncThunk(
    "auth/home-add-members",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/home-add-members`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)

//Delete multiple member
export const Delete_multiple_member_home = createAsyncThunk(
    "auth/destroy-multi-member-from-home",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/destroy-multi-member-from-home`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get get-loggedin-user-homes
export const get_loggedin_user_homes = createAsyncThunk(
    "auth/get-loggedin-user-homes",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-loggedin-user-homes`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
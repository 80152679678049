import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { subsciptionPageValidationStep } from './SubscriptionPageValidation';
import { setSubscription } from '../../../redux/features/subscription';
import { AddSubscriptionDetail, CheckEmailPhone } from '../../../redux/services/subscription-plan';
import { getCountry, getState } from '../../../redux/services/common';
import {PUBLISH_KEY} from '../../../constant/Matcher'
import { Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const SubscriptionPage = () => {
    const stripePromise = loadStripe(PUBLISH_KEY);
    //subscription plan detail
    const subscriptionPlan = useSelector(state => state?.suscription?.planInfo);
    const CountryData = useSelector(state => state?.common?.CountryData);
    const stateData = useSelector(state => state?.common?.StateData);
    const subscriptionPlanDiscount = useSelector(state => state?.suscription?.planDiscount);
    const [selectedPlan, setSelectedPlan] = useState(subscriptionPlan?.selectYear ?? 0);
    const planeAmount = parseInt(selectedPlan == 1 ? subscriptionPlan?.yearlyPriceWithDiscount : subscriptionPlan?.monthAmount)
    const [step, setStep] = useState(1);
    const [totalAmount, setTotalAmount] = useState(planeAmount)
    const [increasePlane, setIncreasePlan] = useState(1)
    const [countryId, setCountryID] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState({
        email: '',
        phone: ''
    });
    const [isDisabledSubscribe, setIsDisabledSubscribe] = useState(false)

    // change the billing frequency
    const handlePlanChange = (event) => {
        const newSelectedPlan = parseInt(event.target.value);
        setSelectedPlan(newSelectedPlan);
        if (newSelectedPlan === 1) {
            const yearAmount = increasePlane * (subscriptionPlan?.yearlyPriceWithDiscount);
            setTotalAmount(yearAmount);
        } else if (newSelectedPlan === 0) {
            const monthAmount = increasePlane * (subscriptionPlan?.monthAmount);
            setTotalAmount(monthAmount);
        }
    };
    const [data, setData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        country: '',
        country_code: '',
        phone: '',
        organization_name: '',
        organization_country_id: '',
        organization_state_id: '',
        organization_city: '',
        organization_address1: '',
        zip_code: '',
        address1: '',
        City: '',
        State_id: '',
        country_id: '',
        subscription_plan_id: subscriptionPlan?.id,
        total_amount: totalAmount,
        billing_frequency_type: ''
    });
    const [formVal, setFormVal] = useState({
        first_name: '',
        // middle_name: '',
        last_name: '',
        email_address: '',
        country: '',
        phone: '',
        organization_name: '',
        organization_country_id: '',
        organization_state_id: '',
        organization_city: '',
        organization_address1: '',
        zip_code: '',
        address1: '',
        City: '',
        State_id: '',
        country_id: '',
    });
    // check validation s
    let checkStepOne = !data.first_name || !data.last_name || !formVal.email_address == '' || !data.email_address || !data.country || !formVal.phone == '' || !data.phone || !data.organization_name || !data.organization_country_id || !data.organization_state_id || !data.organization_city || !data.organization_address1 || !data.zip_code
    let checkStepTwo = !data.card_number || data.card_number.length != 16 || !data.expiry || !data.cvc || !data.address1 || !data.City || !data.State_id || !data.country_id
    
    // steps 
    const next_Step = async () => {
        const currentStep = step;
        const validationErrors = subsciptionPageValidationStep(currentStep, data);
        if (Object.keys(validationErrors).length === 0) {

            const formData = {
                'email_address': data?.email_address,
                'phone': data?.phone,
                'organization_name': data?.organization_name,
                'State_id': data.organization_state_id
            }
            let checkEmailPhoneExist;
            if (step == 1) {
                checkEmailPhoneExist = await dispatch(CheckEmailPhone(formData))
                if (checkEmailPhoneExist?.payload?.status === false) {
                    setShowErrorMsg({
                        email: checkEmailPhoneExist?.payload?.message.email,
                        phone: checkEmailPhoneExist?.payload?.message.phone,
                    });
                }
            }
            if (Object.values(validationErrors).every((error) => !error)) {
                if (checkEmailPhoneExist?.payload?.status == true) {
                    setStep(step + 1);
                } else if (step === 2) {
                    setStep(step + 1)
                }
            } else {
                setFormVal(validationErrors);
            }
        } else {
            setFormVal(validationErrors)
        }

    }
    const previous_Step = () => setStep(step - 1);

    const checkvalidation = (data) => {
        const currentStep = step;
        const validationErrors = subsciptionPageValidationStep(currentStep, data);
        if (Object.values(validationErrors).every((error) => !error)) {
        } else {
            setFormVal(validationErrors);
        }
    }
    const inputChangeHandler = async (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        setFormVal(prevFormVal => ({
            ...prevFormVal,
            [name]: '',
        }));
        setShowErrorMsg(prevErrorMsg => ({
            ...prevErrorMsg,
            [name]: ''
        }))
        setIsDisabledSubscribe(false)
        // Validation check
        if (name === 'organization_name') {
            if (value.length < 5) {
                setFormVal(prevFormVal => ({
                    ...prevFormVal,
                    [name]: 'Organization name must be at least 5 characters long.',
                }));
            } else {
                setFormVal(prevFormVal => ({
                    ...prevFormVal,
                    [name]: '',
                }));
            }
        }
    };

    // on submit data
    const onsubmit = async (e, paymentMethod) => {        
        if (data) {
            data.no_of_licence = increasePlane?.toString();
            data.total_amount = totalAmount;
            data.subscription_plan_id = subscriptionPlan?.id;
            data.paymentMethodId =  paymentMethod
            data.billing_frequency_type = selectedPlan
            data.country_code = data.country
        }
        e.preventDefault();
            const response = await dispatch(AddSubscriptionDetail(data))
            if (response?.payload?.status) {
                localStorage.setItem('subscribe', true)
                dispatch(setSubscription({ ...subscriptionPlan, total_amout: totalAmount, no_of_license: increasePlane }));
                next_Step()
            } else {
                checkvalidation(data);
            }  
            setIsDisabledSubscribe(false)
    }
    // format of card expiry date
    const formatCardExpiration = (event) => {
        let value = event.target.value;
        value = value.replace(/\D/g, '');

        // Allow the user to delete the '/' character
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        } else if (value.length === 2) {
            value = value + '/';
        }
        setData((prevData) => ({
            ...prevData,
            expiry: value,
        }));
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            let value = event.target.value;
            if (value.charAt(value.length - 1) === '/') {
                setData((prevData) => ({
                    ...prevData,
                    expiry: value.slice(0, -1),
                }));
            }
        }
    };
    const dispatch = useDispatch();
    //country and state api
    useEffect(() => {
        dispatch(getCountry());
        if (countryId) {
            dispatch(getState(countryId));
        }
    }, [countryId])

    const formattedCurrency = (currency) => {
        // let pricee = 15450
        const price = currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        return price
    }

    const sortedCountryData = CountryData ?
        [CountryData.find(elem => elem.iso2 === "US"),
        ...CountryData.filter(elem => elem.iso2 !== "US")]
        : [];

    return (
        <div className="subscriptions-step-form-page">
            <section className="subscriptions-sf-sec nsr">
                <div className="subscriptions-sf-inner container">
                    <div className="step-counter-head">
                        <ul>
                            <li className={`${step == 1 ? 'active' : ''}`}>

                                <span className='step-counter-num'>{checkStepOne ? 1 : <i className="fa fa-check"></i>}</span>
                                <div className="font-16 hd-subtitle">Subscription Form</div>
                            </li>
                            <li className={`${step == 2 ? 'active' : ''}`}>
                                <span className='step-counter-num'>{checkStepTwo ? 2 : <i className="fa fa-check"></i>}</span>
                                <div className="font-16 hd-subtitle">Payment Form</div>
                            </li>
                            <li className={`${step == 3 ? 'active' : ''}`}>
                                <span className='step-counter-num'>{step == 3 ? <i className="fa fa-check"></i> : 3}</span>
                                <div className="font-16 hd-subtitle">Getting Started</div>
                            </li>
                        </ul>
                    </div>

                    {/* <!-- ADD STEPS NUMBERIC HERE --> */}
                    <form action="" >
                        {
                            (() => {
                                switch (step) {
                                    case 1:
                                        return (
                                            <>
                                                {/* <!-- STEP 1 --> */}
                                               
                                                <StepOne data={data} formVal={formVal} inputChangeHandler={inputChangeHandler} setCountryID={setCountryID} sortedCountryData={sortedCountryData} stateData={stateData} showErrorMsg={showErrorMsg} CountryData={CountryData} next_Step={next_Step}/>
                                                {/* <!-- STEP 1 --> */}
                                            </>
                                        )
                                    case 2:
                                        return (
                                            <>
                                                {/* <!-- STEP 2 --> */}
                                                <Elements stripe={stripePromise}>
                                               <StepTwo increasePlane={increasePlane} selectedPlan={selectedPlan} handlePlanChange={handlePlanChange}
                                               subscriptionPlanDiscount={subscriptionPlanDiscount}
                                               formattedCurrency={formattedCurrency}
                                               data={data} 
                                               formVal={formVal}
                                                inputChangeHandler={inputChangeHandler} 
                                                setCountryID={setCountryID} 
                                                sortedCountryData={sortedCountryData}
                                                 stateData={stateData}
                                               formatCardExpiration={formatCardExpiration}
                                               handleKeyDown={handleKeyDown}
                                               totalAmount={totalAmount}
                                               previous_Step={previous_Step}
                                               onsubmit={onsubmit}
                                               isDisabledSubscribe={isDisabledSubscribe}
                                               setIsDisabledSubscribe={setIsDisabledSubscribe}
                                                setFormVal={setFormVal}
                                               />
                                               </Elements>
                                                {/* <!-- STEP 2 --> */}
                                            </>
                                        )
                                    case 3:
                                        return (
                                            <>
                                                {/* <!-- STEP 3 --> */}
                                                <div className="congrats-block">
                                                    <i className="fa-regular fa-circle-check mb-2"></i>
                                                    <div className="font-30 hd-title">Congratulations</div>
                                                    <div className="font-16 hd-subtitle mb-4">Your subscription has been successfully activated, granting you access to even more features and benefits.</div>
                                                    <div className="dual-btn">
                                                        <Link to="/login" className="btn btn-primary">Login</Link>
                                                    </div>
                                                </div>
                                                {/* <!-- STEP 3 --> */}
                                            </>
                                        )
                                    default:
                                        return null
                                }
                            })()
                        }
                    </form>
                </div >
            </section >
        </div >
    )
}

export default SubscriptionPage

import React,{useRef} from 'react'
import Modal from 'react-modal';
import { upload_pdf_dddpcsp } from '../../../redux/services/AuthMembers';
import { setPdfData } from '../../../redux/features/AuthMembersSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const UploadAndManually = ({ setIsOpen, isOpen, id }) => {
    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '500px',
            // width: '100%',
            background: '#fff',
            borderRadius: '8px',
            // padding: '20px',
            height: 'auto',
            zIndex: '99',
            transition: 'all 1s ease-in-out'
        }
    };

    const handleManually = async (e) => {
        navigate(`/auth/edit-members/ddd/pcsp/${id}`)
    }

    const handleUploadPdf = async(e) => {
        const { files } = e.target;
        if (files && files.length) {
            let formData = new FormData();
            formData.append('pdf_file', files[0] || '');
            formData.member_id = id;
            console.log(formData,'formDataformDataformDataformData');
            
          const response = await dispatch(upload_pdf_dddpcsp(formData));
        if (response?.payload?.status === true) {
            dispatch(setPdfData(response?.payload?.data))
            navigate(`/auth/edit-members/ddd/pcsp/${id}`)
        }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div >
                        <div  className='btn-right'>
                        <button type="button" className="btn-close close-deactivate" onClick={() => setIsOpen(false)}></button>
                        </div>
                        <h4>Choose your preferred option</h4>
                    </div>
                    <div className="modal-body">
                        <div className='begin_modal_btn'>
                        <input onChange={(e)=>handleUploadPdf(e)} multiple={false} ref={fileInputRef} type='file' accept="application/pdf" hidden/>
                            <button type="button" className="btn btn-light p-4" onClick={()=>fileInputRef.current.click()}><span><svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.16671 1.89111V5.33323C9.16671 5.79994 9.16671 6.0333 9.25754 6.21156C9.33743 6.36836 9.46491 6.49584 9.62171 6.57574C9.79997 6.66656 10.0333 6.66656 10.5 6.66656H13.9422M10.8334 10.8332H4.16671M10.8334 14.1665H4.16671M5.83337 7.49984H4.16671M9.16671 1.6665H4.83337C3.43324 1.6665 2.73318 1.6665 2.1984 1.93899C1.72799 2.17867 1.34554 2.56112 1.10586 3.03153C0.833374 3.56631 0.833374 4.26637 0.833374 5.6665V14.3332C0.833374 15.7333 0.833374 16.4334 1.10586 16.9681C1.34554 17.4386 1.72799 17.821 2.1984 18.0607C2.73318 18.3332 3.43324 18.3332 4.83337 18.3332H10.1667C11.5668 18.3332 12.2669 18.3332 12.8017 18.0607C13.2721 17.821 13.6545 17.4386 13.8942 16.9681C14.1667 16.4334 14.1667 15.7333 14.1667 14.3332V6.6665L9.16671 1.6665Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </span> Upload document for auto-fill</button>
                            <hr  className='m-0'/>
                            <button type="button" className="btn p-4 orang" onClick={() => handleManually()}><span><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8333 6.66689L2.16663 18.3336M15.5 12.5002H7.99996M5.99996 15.8336H11.6143C11.8182 15.8336 11.9201 15.8336 12.016 15.8105C12.101 15.7901 12.1823 15.7564 12.2569 15.7108C12.341 15.6592 12.413 15.5872 12.5571 15.443L16.75 11.2502C16.9491 11.0511 17.0487 10.9515 17.1288 10.8634C18.8623 8.95633 18.8623 6.04412 17.1288 4.13708C17.0487 4.04892 16.9491 3.94936 16.75 3.75022C16.5508 3.55109 16.4513 3.45152 16.3631 3.37139C14.4561 1.63792 11.5439 1.63792 9.63682 3.37139C9.54866 3.45152 9.4491 3.55109 9.24996 3.75022L5.05715 7.94303C4.91302 8.08716 4.84096 8.15922 4.78943 8.24332C4.74374 8.31788 4.71007 8.39916 4.68965 8.48419C4.66663 8.5801 4.66663 8.68201 4.66663 8.88584V14.5002C4.66663 14.9669 4.66663 15.2003 4.75745 15.3785C4.83735 15.5353 4.96483 15.6628 5.12163 15.7427C5.29989 15.8336 5.53325 15.8336 5.99996 15.8336Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </span> Manually fill in details of member</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UploadAndManually
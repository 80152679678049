import { createSlice } from "@reduxjs/toolkit";
import { getNotificationList, readMsgUpdate } from "../services/AuthNotification";

const initialState = {
    isLoading: false,
    error: null,
    NotificationList:[]
}


const AuthNotificationSlice = createSlice({
    name: 'AuthNotificationSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ===========getNotificationList============== //
        builder.addCase(getNotificationList.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getNotificationList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.NotificationList = action?.payload?.data
        });
        builder.addCase(getNotificationList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========readMsgUpdate============== //
        builder.addCase(readMsgUpdate.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(readMsgUpdate.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(readMsgUpdate.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
})
export default AuthNotificationSlice?.reducer;
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import defaultImg from "../../../Images/defaultImg.png";
import { Pagination } from '../../Pagination';
import { get_all_roles } from '../../../redux/services/common';
import { Activate_homes, get_home_assign_users } from '../../../redux/services/AuthHomes';
import Add_new_home_user from './Add_new_home_user';
import Delete_home_user from './Delete_Home_user';
const HomeUsers = ({ id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const EditHomeData = useSelector((state) => state?.AuthHomes?.EditHomeData);
    const [delteModal, setDeleteModal] = useState({
        modal: false,
        user_id: '',
    });
    const [search, setSearch] = useState({
        role: '',
        status: '',
        page: currentPage,
        listData: 10,
        home: '',
        searchBar: '',
        id: id?.id
    });
    useEffect(() => {
        dispatch(get_all_roles());
        // dispatch(Activate_homes());
        dispatch(get_home_assign_users(search));
    }, [search, currentPage]);
    const homeAssignUsers = useSelector((state) => state?.AuthHomes?.GetHomeAssignUsers?.sendData);
    const RoleData = useSelector(state => state?.common?.RoleData);
    const assingUserTotalPage = useSelector(state => state?.AuthHomes?.GetHomeAssignUsers);
    const checkAll = () => {
        const allUserIds = homeAssignUsers?.filter(user => !user.is_active === false).map(user => user.id);
        setSelectedUsers(checked ? [] : allUserIds);
        setChecked(!checked);
    };
    const handleSingleCheck = (userId) => {
        const currentIndex = selectedUsers.indexOf(userId);
        const newSelectedUsers = [...selectedUsers];
        if (currentIndex === -1) {
            newSelectedUsers.push(userId);
        } else {
            newSelectedUsers.splice(currentIndex, 1);
        }

        setSelectedUsers(newSelectedUsers);
    };
    //pagination
    // const totalPages = Math.ceil(totalItems / itemsPerPage);
    const totalPages = assingUserTotalPage?.totalNoPages;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearch({
            ...search,
            page: pageNumber
        });
    };
    const handleRoleChange = (e) => {
        setSearch({
            ...search,
            role: e.target.value
        });
    };
    const handleStatusChange = (e) => {
        setSearch({
            ...search,
            status: e.target.value
        });
    };
    const handleHomeChange = (e) => {
        setSearch({
            ...search,
            home: e.target.value
        });
    };
    const handleSearchBar = (e) => {
        setCurrentPage(1)
        setSearch({
            ...search,
            searchBar: e.target.value
        });
    };
    return (
        <>
            <div className="right-main-header search-sec">
                <div className="left-side-content">
                </div>
                <div className="left-side-content">
                    <button type="button" className="btn btn-primary link" onClick={() => setModalIsOpen(true)} disabled={EditHomeData?.is_active !== true ? true : false} >
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        Assign New User
                    </button>
                </div>
            </div>
            {
                !showFilter ?
                    <div className="table-btn-sec">
                        <div className="form-group searchbar"><input type="text" name="" id="" className="form-control" placeholder="Search name, email" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <button type="button" className="btn btn-light" onClick={() => setShowFilter(true)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            More Filters
                        </button>
                    </div> : ''
            }
            {showFilter ?
                <div className="table-btn-sec more-filter">
                    <button type="button" className="btn btn-light" onClick={() => (setShowFilter(false), setSearch({ ...search, role: '', status: '', searchBar: '' }))}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Close Filters
                    </button>
                    <button className='clear-filter' onClick={() => setSearch({ ...search, role: '', status: '', searchBar: '' })}>Clear all filters</button>
                    <div className="filter-option">
                        <div className="form-group searchbar">
                            <label className="form-label">Search</label>
                            <input type="text" name="" id="" className="form-control" placeholder="Search name, email" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Role</label>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => handleRoleChange(e)} value={search?.role}>
                                <option value=''>Select role</option>
                                {RoleData && RoleData?.map((elem) => {
                                    return <option value={elem?.id}>{elem?.role_name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Status</label>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => handleStatusChange(e)} value={search?.status}>
                                <option value=''>Select status</option>
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                            </select>
                        </div>
                    </div>
                </div> : ''
            }
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={checkAll}
                                                />
                                                <label className="form-check-label">Name</label>
                                            </div>
                                        </th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {totalPages == 0 ?
                                        <tr>
                                            <td colspan="7">
                                                <div className='text-center text-capitalize'>No user found</div>
                                            </td>
                                        </tr>
                                        :
                                <tbody>
                                    {homeAssignUsers &&
                                        homeAssignUsers?.map((elem) => {
                                            return (

                                                <tr key={elem.id}>
                                                    <td>
                                                        {
                                                            <div className='td-form-check'>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={selectedUsers.includes(elem.id)}
                                                                        onChange={() => handleSingleCheck(elem.id)}
                                                                        disabled={elem?.is_active === false ? true : false}
                                                                    />
                                                                </div>
                                                                <div className="profile-sec">
                                                                    <span className="name "><img src={elem?.profile_img_url ? elem?.profile_img_url : defaultImg} /></span>
                                                                    <div
                                                                        className="font-14 hd-title"
                                                                    >
                                                                        {elem?.first_name} {elem?.last_name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            (() => {
                                                                if (elem?.is_active === true) {
                                                                    return (
                                                                        <div className="badges">
                                                                            <span className="actives"></span>
                                                                            Active
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div className="badges">
                                                                            <span style={{ backgroundColor: 'orange' }}></span>
                                                                            Inactive
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                    </td>
                                                    <td>{elem?.role_name}</td>
                                                    {/* <td>{elem?.username}</td> */}

                                                    <td>{elem?.email_address}</td>
                                                    <td>
                                                        {EditHomeData?.is_active &&
                                                        <Link className='delete-link' to='' onClick={() => setDeleteModal({ ...delteModal, modal: true, user_id: elem?.id })}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    </Link>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colspan="7">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                        }
                            </table>
                        </div>
                    </div>
                </div>
                <Add_new_home_user modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={id?.id} search={search} type='add_new_user' />
                <Delete_home_user delteModal={delteModal} setDeleteModal={setDeleteModal} search={search} id={id?.id} type='delete_user'/>
            </div>
        </>
    )
}


export default HomeUsers;
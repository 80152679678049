import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Pdf from '../../../Images/file_type_wrap.png'
import { get_communication_by_id } from '../../../redux/services/AuthCommunication'
import { dayDateFormateOpenMAR, formatAMPM } from '../../../components/Common/Common'
import {fileFormat} from '../../../components/Common/Common'


const EditMessage = ({ selectedMember, MessageData, onModalToggle, onDeleteModal, setAddNewModal }) => {
    const dispatch = useDispatch();
    const { handleSubmit, register, setValue, formState: { errors } } = useForm();

    const userPermissions = useSelector((state) => state?.common?.userPermissions);

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setValue('type', MessageData?.type)
        setValue('notification_level', MessageData?.notification_level)
    }, [MessageData])

    const handleEdit = async () => {
        let response = await dispatch(get_communication_by_id(MessageData.id))
        if (response?.payload?.status) {
            onModalToggle(false)
            setAddNewModal(true)
        }
    }
    const onSubmit = () => {

    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleDocClick = (item) => {
        if (userPermissions && userPermissions.includes('View')) {
            window.open(item, '_blank')
        }
    }

    const date = dayDateFormateOpenMAR(MessageData?.created_date)
    const time = formatAMPM(MessageData?.created_date)
let isEdit = (userPermissions && userPermissions.includes('Edit')) || (userPermissions && userPermissions.includes('Delete'))
    return (
        <>
            <div className="modal fade new_medi_modal show communication_edit_modal">
                <div className="modal-dialog msg">
                    <div className="modal-content">
                        <div className="modal-header">
                       {isEdit &&
                           <h5 className="modal-title">
                                {userPermissions && userPermissions.includes('Edit') &&
                                    <div onClick={() => handleEdit()}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17 9.00006L13 5.00006M1.5 20.5001L4.88437 20.124C5.29786 20.0781 5.5046 20.0551 5.69785 19.9925C5.86929 19.937 6.03245 19.8586 6.18289 19.7594C6.35245 19.6476 6.49955 19.5005 6.79373 19.2063L20 6.00006C21.1046 4.89549 21.1046 3.10463 20 2.00006C18.8955 0.895489 17.1046 0.895488 16 2.00006L2.79373 15.2063C2.49955 15.5005 2.35246 15.6476 2.24064 15.8172C2.14143 15.9676 2.06301 16.1308 2.00751 16.3022C1.94496 16.4955 1.92198 16.7022 1.87604 17.1157L1.5 20.5001Z"
                                                stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                }
                                {userPermissions && userPermissions.includes('Delete') &&
                                    <div onClick={() => onDeleteModal(true)}>
                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
                                                stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                }
                            </h5>
                            }
                            <button type="button" className="btn-close" onClick={() => onModalToggle(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="apnorht_main">
                                <div className="apnorht_msg">
                                    <div className="apnorht_msg_icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.5 10.5H7.51M12 10.5H12.01M16.5 10.5H16.51M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18ZM8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5ZM12.5 10.5C12.5 10.7761 12.2761 11 12 11C11.7239 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.7239 10 12 10C12.2761 10 12.5 10.2239 12.5 10.5ZM17 10.5C17 10.7761 16.7761 11 16.5 11C16.2239 11 16 10.7761 16 10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5Z"
                                                stroke="#079455" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                    </div>
                                    <div className="apnorht_head_inner">
                                        <h4 className="font-18 hd-title">{selectedMember.first_name} {selectedMember.last_name}</h4>
                                        <span className="font-14 hd-subtitle">{date} {time}</span>
                                    </div>
                                </div>
                            </div>
                            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                                <div className="col-md-6 mb-6">
                                    <label for="" className="form-label">Type*</label>
                                    <select className="form-select" aria-label="Default select example" name='type' {...register('type', { required: 'true' })} disabled={true}>
                                        <option value="">-Please Select -</option>
                                        <option value="1">Health</option>
                                        <option value="1">US/Arizona</option>
                                        <option value="2">US/Arizona</option>
                                        <option value="3">US/Arizona</option>
                                    </select>
                                    {errors.type && errors.type.type === "required" && <span className="error">Type is required</span>}
                                </div>
                                <div className="col-md-6 mb-6">
                                    <label for="" className="form-label">Notification Level*</label>
                                    <select className="form-select" aria-label="Default select example" name='notification_level' {...register('notification_level', { required: 'true' })} disabled={true}>
                                        <option value="">-Please Select -</option>
                                        <option value="critical">Critical</option>
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">Low</option>
                                    </select>
                                    {errors.notification_level && errors.notification_level.type === "required" && <span className="error">Notification Level is required</span>}
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="apnt_gym my-4">
                                        <h4 className="font-14 hd-title">{MessageData.title}</h4>
                                        <p className="font-14 hd-subtitle">
                                            {isExpanded ? MessageData.description : MessageData.description.length > 50 ? `${MessageData.description.substring(0, 100)}...` : MessageData.description}
                                            {MessageData.description.length > 50 &&
                                                <a href="#" onClick={toggleExpand}>
                                                    {isExpanded ? 'View Less' : 'View More'}
                                                </a>
                                            }
                                        </p>
                                        {isExpanded && (
                                            <div className="full-content">
                                                {/* Render full content here, if different from description */}
                                                <p>{MessageData.fullContent}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="documents_file_pdf">
                                        <p className="font-14 hd-subtitle mb-2">Attached Documents</p>

                                        <div className="row">
                                            {MessageData && MessageData.communicationDocument.length > 0 && MessageData.communicationDocument.map((item) => {                                                
                                                return (
                                                    <div className="col-md-3 brain_image" onClick={() => handleDocClick(item.document_name)}>
                                                        {/* <img src={Pdf} alt="" /> */}
                                                        <div className= {`file_type ${fileFormat[item.file_type]}`}>{fileFormat[item.file_type]}</div>
                                                        <p className="font-14 hd-subtitle">{item.file_name.length > 10
														? item.file_name.substring(0, 10) + '...'
														: item.file_name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default EditMessage
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UrlBar from './UrlBar'
import Header from './Header'
import BoardView from './BoardView'
import ListView from './ListView'
import AddNewTask from './AddNewTask'
import StatusModal from './StatusModal'
import DeleteTask from "./DeleteTask";
import { reSetEditTasksData } from '../../../redux/features/AuthTasksSlice'
import { get_task_board_view, edit_task, destroy_task, Task_status_change, get_task_table_view, get_all_admin_home_users } from '../../../redux/services/AuthTasks'
import { setUserPermissions } from '../../../redux/features/commonSlice';

const initionalSearch = {
    page: 1,
    listData: 10,
    searchBar: '',
    role: '',
    task_status: '',
    assigned_to: '',
    due_date: ''
}
const Tasks = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
    const BoardViewTasks = useSelector(state => state?.AuthTasks?.BoardViewTasks);
    const moduleData = useSelector(state => state.common.moduleData);
    const userPermissions = useSelector(state => state.common.userPermissions);

    const [addNewTask, setAddNewTask] = useState(false)
    const [taskStatus, setTaskStatus] = useState({})
    const [taskStatusModal, setTaskStatusModal] = useState(false)
    const [deleteTask, setDeleteTask] = useState('')
    const [search, setSearch] = useState(initionalSearch)
    const [viewType, setViewType] = useState('board_view')

    useEffect(() => {
        dispatch(get_all_admin_home_users())
    }, [])

    useEffect(() => {
        if (moduleData.length > 0) {      
          const module = moduleData.find(item => item.module_name === 'Tasks');
          if (module) {
            const permissionsArray = module.permission.map(item => item.permission);
            dispatch(setUserPermissions(permissionsArray));
          }
        }
      }, [moduleData, dispatch]);

    useEffect(() => {
        if (id) {
            // Combine all task arrays to search through
            const allTasks = [
                ...BoardViewTasks.pending,
                ...BoardViewTasks['past due'],
                ...BoardViewTasks['pending approval'],
                ...BoardViewTasks.completed
            ];
            // Find the task that matches the ID
            const task = allTasks.find(task => task.id === id);
            if(task){
                setTaskStatus(task)
                setTaskStatusModal(true)
            }
        }

    }, [id])

    useEffect(() => {
        let data = search
        if (!addNewTask) {
            data.role = currentUserRole
            if (viewType === 'board_view') {
                dispatch(get_task_board_view(data))
            } else {
                data.role = currentUserRole
                dispatch(get_task_table_view(data))
            }
        }
    }, [search, viewType, addNewTask, taskStatus, deleteTask, currentUserRole])

    const handleViewChange = (view) => {
        setViewType(view)
    }
    const handleTaskClick = async (task) => {
        if (userPermissions?.includes('View')) {
        let response = await dispatch(edit_task(task.id))
        if (response?.payload?.status) {
            setTaskStatus(response?.payload?.data)
            setTaskStatusModal(true)
        }
    }
    }

    const handleEditTask = (id) => {
        setTaskStatusModal(false)
        setAddNewTask(true)
    }

    const handleDeleteModal = (id) => {
        setDeleteTask(id)

    }
    const handleDeleteTask = async () => {
        let response = await dispatch(destroy_task(deleteTask))
        if (response?.payload?.status) {
            setDeleteTask('')
            setTaskStatus({})
            setTaskStatusModal(false)
            dispatch(reSetEditTasksData())
            if(id){
                navigate('/auth/tasks')
            }
        }
    }

    const handleMarkAsComplate = async (task, status) => {
        if (status !== '') {
            let payload = {
                task_id: task.id,
                task_status: status.toLowerCase()
            }
            let response = await dispatch(Task_status_change(payload))
            if (response?.payload?.status) {
                setDeleteTask('')
                setTaskStatus({})
                setTaskStatusModal(false)
                dispatch(reSetEditTasksData())
            }
        }
    }

    const handleToggalStatusModal = (data) => {
        setTaskStatus({})
        setTaskStatusModal(false)
        dispatch(reSetEditTasksData())
        if(id){
            navigate('/auth/tasks')
        }
    }

    const handleSearchBar = (e) => {
        setSearch({
            ...search,
            page: 1,
            searchBar: e.target.value,
        });
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="right-main-sec appointment_dash_sec">
                                <UrlBar />
                                <Header setAddNewTask={setAddNewTask} viewType={viewType} onViewChange={handleViewChange} handleSearchBar={handleSearchBar} search={search} setSearch={setSearch} />
                                {viewType === 'board_view' && <BoardView onTaskClick={handleTaskClick} handleMarkAsComplate={handleMarkAsComplate} />}
                                {viewType === 'list_view' && <ListView onTaskClick={handleTaskClick} search={search} setSearch={setSearch} />}
                                {addNewTask && <AddNewTask setAddNewTask={setAddNewTask} setTaskStatusModal={setTaskStatusModal} setTaskStatus={setTaskStatus} taskStatus={taskStatus} />}
                            </div>
                        </div>
                    </div>
                </div>
                {taskStatusModal && <StatusModal taskDetail={taskStatus} toggalStatusModal={handleToggalStatusModal} onEditTask={handleEditTask} onDeleteTask={handleDeleteModal} onMarkAsComplate={handleMarkAsComplate} />}
                {deleteTask !== '' && <DeleteTask deleteTask={deleteTask} setDeleteTask={setDeleteTask} onDeleteSubmit={handleDeleteTask} />}
            </main>
        </div>
    )

}
export default Tasks
import React from "react";
import { useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import { monthDayYearFormat } from '../../../components/Common/Common';


const fileFormate = {
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'msword': 'doc',
    'jpeg': 'jpeg',
    'jpg': 'jpg',
    'png': 'png',
    'pdf': 'pdf'
}
const TableView = ({ documentsList, currentPage, totalPages, handlePageChange, onRowClick }) => {

    return (
        <div className="main-content-sec doc_info_data medicat_tb_cont">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="table-profile">
                                                {/* <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckIndeterminate" />
                                                </div> */}
                                                Name
                                                {/* <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004" stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                            </div>
                                        </th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Uploaded by</th>
                                        <th scope="col">Uploaded date</th>
                                        <th scope="col">Attached to
                                            {/* <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004" stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg> */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentsList && documentsList.map((item, index) => {
                                        let name = item.file_name.split('.')
                                        let type = item.file_type.split('/')

                                        return (
                                            <tr>
                                                <td>
                                                    <div className="table-profile">
                                                        {/* <div className="form-check appointment_check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
                                                        </div> */}
                                                        <div className="appointment-member" onClick={() => onRowClick(item.id)}>
                                                            {name[0]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="hd-subtitle text-dark text-uppercase">{fileFormate[type[1]]}</td>
                                                <td className="hd-subtitle text-dark">{item?.module?.module_name}</td>

                                                <td className="hd-subtitle text-dark">{item?.createdPlans?.first_name}</td>
                                                {/* <td className="hd-subtitle text-dark">12/07/2024</td> */}
                                                <td className="approval-status-tbl">{monthDayYearFormat(item.created_date, true).replaceAll('-', '/')}</td>

                                                <td>
                                                    <div className="table-profile">

                                                        {/* <div className="profile-sec  appointment-member">
                                                            {item?.documentAttachedTo.map((member) => {
                                                                let shortName = member.member?.first_name?.charAt(0)?.toUpperCase() + member.member?.last_name?.charAt(0)?.toUpperCase() || '';
                                                                return (
                                                                    <>
                                                                        {item?.documentAttachedTo?.length < 6 &&
                                                                            <div className="profile-sec  appointment-member">
                                                                                <span className="name ">
                                                                                    {member.member?.profile_pic ?
                                                                                        <img src={member.member?.profile_pic} /> :
                                                                                        shortName
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                        {item?.documentAttachedTo?.length > 5 &&
                                                                            <span className="appointment_pluse">+{item?.documentAttachedTo?.length - 6}</span>
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </div> */}

                                                        <div className="profile-sec appointment-member">
                                                            {item?.documentAttachedTo.map((member, index) => {
                                                                if (index < 5) {
                                                                    let shortName = member.member?.first_name?.charAt(0)?.toUpperCase() + member.member?.last_name?.charAt(0)?.toUpperCase() || '';
                                                                    return (
                                                                        <div className="profile-sec appointment-member" key={index}>
                                                                            <span className="name">
                                                                                {member.member?.profile_pic ?
                                                                                    <img src={member.member?.profile_pic} alt={`${member.member.first_name}'s profile`} /> :
                                                                                    shortName
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null; // For members beyond the 5th one
                                                            })}
                                                            {item?.documentAttachedTo.length > 5 && (
                                                                <span className="appointment_plus">+{item.documentAttachedTo.length - 5}</span>
                                                            )}
                                                        </div>



                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan="7">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableView;

import React from "react";

const DeleteModal = ({onDeleteToggal, onDeleteSubmit}) => {

    return(
        <div className="modal fade delete_model_sec show">
            <div className="modal-dialog delete_model">
			<div className="modal-content ">
				<div className="modal-header">
					<button type="button" className="btn-close" onClick={()=>onDeleteToggal('')}></button>
				</div>
				<div className="delete_box">
					<p className="font-18 ">Are you sure you want to delete </p>
					<p className="font-14 hd-subtitle delete_model_text">Please note that this action cannot be undone.</p>

					<div className="cancel_delete">
						<button type="button" className="btn btn-light" onClick={()=>onDeleteToggal('')}>Cancel</button> &nbsp;
						<button type="button" className="btn btn-outline-danger bg-danger text-white" onClick={()=>onDeleteSubmit()}>Yes Delete</button>
					</div>
				</div>
			</div>
            </div>
		</div>
    )
}
export default DeleteModal
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import ResultNotFound from './ResultNotFound';
import { monthDayYearFormat } from '../../../components/Common/Common'

const ListView = ({ search, setSearch, onTaskClick }) => {
    console.log(search, 'searchsearch');

    const ListViewTasks = useSelector(state => state?.AuthTasks?.ListViewTasks?.tasks);
    const totalPages = useSelector(state => state?.AuthTasks?.ListViewTasks?.totalPages);

    const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending order
    const [sortField, setSortField] = useState('title'); // Default sort field

    const handlePageChange = (pageNumber) => {
        setSearch({
            ...search,
            page: pageNumber
        });
    };

    const handleSort = (field) => {
        // Toggle sort order between 'asc' and 'desc'
        const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    // Sort ListViewTasks based on selected field and order
    const sortedTasks = ListViewTasks && [...ListViewTasks].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (sortOrder === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
    });

    return (
        <div className="main-content-sec vit_info_data medicat_tb_cont task_list_view">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            {sortedTasks && sortedTasks.length > 0 ?
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="table-profile">
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckIndeterminate" />
                                                    </div> */}
                                                    Task
                                                    <svg
                                                        className="ms-1"
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 12 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        onClick={() => handleSort('title')} // Sorting by title
                                                    >
                                                        <path
                                                            d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                            stroke="#475467"
                                                            strokeWidth="0.888889"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                Due date
                                                {/* <svg
                                                    className="ms-1"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    onClick={() => handleSort('due_date')} // Sorting by due date
                                                >
                                                    <path
                                                        d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                        stroke="#475467"
                                                        strokeWidth="0.888889"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg> */}
                                            </th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Assigned to</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedTasks.map((item, index) => {
                                            let shortName = item?.userAssigned?.first_name?.charAt(0)?.toUpperCase() + item?.userAssigned?.last_name?.charAt(0)?.toUpperCase() || '';
                                            return (
                                                <tr key={index}>
                                                    <td className="hd-subtitle text-dark">
                                                        <div className="table-profile">
                                                            <div className="profile-sec appointment-member">
                                                                {/* <div className="form-check appointment_check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
                                                                </div> */}
                                                                <div className="profile-info">
                                                                    <p className="font-14 hd-title">{item.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="hd-subtitle text-dark">{monthDayYearFormat(item.due_date, true)}</td>
                                                    <td className="hd-subtitle text-dark">
                                                        <div className="badges task_list_status">
                                                            <span className={item.task_status}></span>
                                                            {item.task_status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-profile">
                                                            <div className="profile-sec appointment-member">
                                                                <span className="name">
                                                                    {item?.userAssigned?.profile_pic ?
                                                                        <img src={item?.userAssigned?.profile_pic} alt="profile" /> :
                                                                        shortName
                                                                    }
                                                                </span>
                                                                <div className="profile-info">
                                                                    <p className="font-14 hd-title">{item?.userAssigned?.first_name} {item?.userAssigned?.last_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="task_table_action" onClick={() => onTaskClick(item)}>
                                                        <p>Open task</p>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td colSpan="7">
                                                <Pagination
                                                    currentPage={search?.page}
                                                    totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <ResultNotFound />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListView;

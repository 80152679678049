import React from 'react'
import noRoles from '../../../../Images/noroles.png'
const NoRoles = () => {
	return (
		<div className="main-content-sec">
			<div className="empty-sec">
				<img src={noRoles} alt="" title="" />
				<div className="font-16 hd-title">There are no roles yet</div>
                    {/* <button type="button" className="btn btn-primary" onClick={() => setModalIsOpen(true)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 4.16699V15.8337M4.16602 10.0003H15.8327"
                                stroke="#FCFCFD" stroke-width="1.66667"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        New Role
                    </button> */}
			</div>
		</div>
	)
}

export default NoRoles
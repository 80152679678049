import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UrlBar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url)
    }
    return (
        <ol className="breadcrumb">
            <li className="breadcrumb-item" onClick={() => handleClick('/auth/notification')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.6666 8.75008V5.66675C16.6666 4.26662 16.6666 3.56655 16.3942 3.03177C16.1545 2.56137 15.772 2.17892 15.3016 1.93923C14.7668 1.66675 14.0668 1.66675 12.6666 1.66675H7.33331C5.93318 1.66675 5.23312 1.66675 4.69834 1.93923C4.22793 2.17892 3.84548 2.56137 3.6058 3.03177C3.33331 3.56655 3.33331 4.26662 3.33331 5.66675V14.3334C3.33331 15.7335 3.33331 16.4336 3.6058 16.9684C3.84548 17.4388 4.22793 17.8212 4.69834 18.0609C5.23312 18.3334 5.93318 18.3334 7.33331 18.3334H9.99998M11.6666 9.16675H6.66665M8.33331 12.5001H6.66665M13.3333 5.83341H6.66665M15 17.5001V12.5001M12.5 15.0001H17.5"
                            stroke="#667085" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
            </li>
            <li className="breadcrumb-item active" onClick={() => handleClick('/auth/notification')}>Notifications</li>
        </ol>
    )
}
export default UrlBar
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './SideBar';
import { useParams } from 'react-router-dom';
import UrlBar from './UrlBar'
import AddCommunication from "./AddCommunication";
import Messages from './Messages'
import { New_ComSvg, SearchSvg } from '../../../Images/CommunicationSvg';
import { get_home_with_member, get_communication_member } from '../../../redux/services/AuthCommunication'
import { reSetCommunicationById } from '../../../redux/features/AuthCommunicationSlice'

const CommunicationDetails = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const communicationMemberList = useSelector(state => state?.AuthCommunication?.communicationMemberList?.member);
	const home_name = useSelector(state => state?.AuthCommunication?.communicationMemberList?.home_name);
	const userPermissions = useSelector((state) => state?.common?.userPermissions);


	const [addNewModal, setAddNewModal] = useState(false);
	const [selectedMemberId, setSelectedMemberId] = useState('');
	const [selectedMember, setSelectedMember] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [reloadData, setReloadData] = useState(false);

	useEffect(() => {
		if (id && !addNewModal) {
			let member_id = localStorage.getItem('member_id')
			if (member_id) {
				setSelectedMemberId(member_id)
				let data = {
					page: 1,
					listData: 10,
					searchBar: '',
					member_id: member_id
				}
				dispatch(get_communication_member(data))
			}
			dispatch(get_home_with_member(id))
		}
	}, [id, addNewModal])

	useEffect(() => {
		
		let filterdMember = communicationMemberList && communicationMemberList.find((item) => item.id === selectedMemberId)
		console.log(filterdMember,'communicationMemberListcommunicationMemberList');
		if (filterdMember) {
			setSelectedMember(filterdMember)
		}
	}, [selectedMemberId])

	useEffect(() => {
		if (reloadData) {
			let data = {
				page: 1,
				listData: 10,
				searchBar: '',
				member_id: selectedMemberId
			}
			dispatch(get_communication_member(data))
			setReloadData(false)
		}
	}, [reloadData])


	const handleMemberSelect = (id) => {
		if (userPermissions && userPermissions.includes('View')) {
			setSelectedMemberId(id)
			let data = {
				page: 1,
				listData: 10,
				searchBar: '',
				member_id: id
			}
			dispatch(get_communication_member(data))
		}
	}

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber)
		let data = {
			page: pageNumber,
			listData: 10,
			searchBar: '',
			member_id: selectedMemberId
		}
		dispatch(get_communication_member(data))
	};

	const handleAddNew = () => {
		setAddNewModal(true)
		dispatch(reSetCommunicationById())
	}

	const handleSearchBar = (e) => {
		let data = {
			page: 1,
			listData: 10,
			member_id: selectedMemberId,
			searchBar: e.target.value,
		}
		dispatch(get_communication_member(data))
	};

	return (
		<div id="layoutSidenav">
			<SideBar />
			<div id="layoutSidenav_content">
				<main>
					<UrlBar homeName={home_name} />
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12 col-lg-12 col-12">
								<div className="right-main-sec appointment_dash_sec">

									<div className="adminstrat_btn_main member_commun">
										<h2 className="font-16 hd-title member_comm">Members</h2>
										<div className="adminstrat_btn">
											{userPermissions && userPermissions.includes('Add') &&
												<button type="button" className="btn btn-primary" onClick={() => handleAddNew()}>
													<New_ComSvg />
													New Com Log
												</button>
											}
										</div>
									</div>
									<div className="member_sectio">
										<div className="nav nav-pills tabs_sec_comm">
											{communicationMemberList &&
												// Create a new array with the selected member at the top
												[
													communicationMemberList.find(item => item.id === selectedMemberId),
													...communicationMemberList.filter(item => item.id !== selectedMemberId)
												]
													.map((item) => {
														if (!item) return null; // Skip rendering if item is undefined (in case selectedMemberId doesn't match any)
														let name = item?.first_name.charAt(0) + item?.last_name.charAt(0);
														return (
															<div className={`table-profile ${item.id === selectedMemberId ? 'active' : ''}`} key={item.id}>
																<div className="profile-sec appointment-member" onClick={() => handleMemberSelect(item.id)}>
																	{item.profile_pic ? <img src={item.profile_pic} alt="Profile" /> : <span className="communication_hg">{name}</span>}
																	<span className="oln"></span>
																	<div className="profile-info">
																		<p className="font-14 hd-title">{item.first_name} {item.last_name}
																			<span className="d-block hd-subtitle text-start">{item.ahss_id}</span>
																		</p>
																	</div>
																</div>
															</div>
														);
													})
											}
										</div>
										<div className="tab-content communication_logs" id="v-pills-tabContent">
											<div className="saf-img-upload">
												<div className="form-group searchbar">
													<input type="text" name="" id="" className="form-control" maxLength={20} placeholder="Search" onChange={(e) => handleSearchBar(e)} />
													<SearchSvg />
												</div>
											</div>
											<Messages handlePageChange={handlePageChange} currentPage={currentPage} setReloadData={setReloadData} setAddNewModal={setAddNewModal} selectedMember={selectedMember} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			{addNewModal && <AddCommunication onModalToggle={setAddNewModal} currentHomeName={home_name} currentMemberId={selectedMemberId} selectedMember={selectedMember} />}
		</div>
	);
};

export default CommunicationDetails;

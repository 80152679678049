import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { user_without_assign_home, home_add_users, get_home_assign_users, member_without_assign_home, home_add_members, get_home_assign_members } from '../../../redux/services/AuthHomes';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import defaultImg from '../../../Images/defaultImg.png';
import Select from "react-select";
const Add_new_home_user = ({ modalIsOpen, setModalIsOpen, id, search, type }) => {
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const [showEr, setShowEr] = useState(false);
  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      maxWidth: '750px',
      // width: '100%',
      background: '#fff',
      borderRadius: '8px',
      padding: '20px',
      overflow: 'unset',
      // height: 'auto',
      zIndex: '99',
      transition: 'all 1s ease-in-out'
    }
  };
  const { handleSubmit, control } = useForm({ mode: "onChange", });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(member_without_assign_home(id));
    dispatch(user_without_assign_home(id));
    dispatch(get_home_assign_users(search));
    dispatch(get_home_assign_members(search));
  }, [id])
  //user without Assign Home
  const User_withou_assign_home = useSelector((state) => state?.AuthHomes?.GetHomeWithoutAssignUsers);
  //user without Assign Home
  const Member_without_assign_home = useSelector((state) => state?.AuthHomes?.MemberWithoutAssignHome);

  //multi select user
  const userListData = User_withou_assign_home?.map((item) => ({
    value: item?.id,
    label: (
      <div className="add-img-sec">
        <img src={item?.profile_img_url ? item?.profile_img_url : defaultImg} />
        <span>{`${item?.first_name} ${item?.last_name}`}</span>
      </div>
    )
  }));
  const memberListData = Member_without_assign_home?.map((item) => ({
    value: item?.id,
    label: (
      <span>{`${item?.first_name} ${item?.last_name}`}</span>
    )
  }));
  // Remove a selected option
  const handleRemoveOption = (optionToRemove) => {
    if (optionToRemove && optionToRemove.value) {
      setSelectedSubOptions(selectedSubOptions.filter(option => option.value !== optionToRemove.value));
    }
  };
  const handleUserChange = (selectedSubOptions) => {
    Object.keys(selectedSubOptions).length > 0 ? setShowEr(false) : setShowEr(true);
    setSelectedSubOptions([selectedSubOptions]);
  };

  const onSubmit = async () => {
    selectedSubOptions?.length > 0 ? setShowEr(false) : setShowEr(true);
    if (selectedSubOptions?.length > 0) {
      const userData = { home_table_id: id, userAssign: [selectedSubOptions[0]?.value] };
      const memberData = { home_table_id: id, memberAssign: [selectedSubOptions[0]?.value] };
      if (type == 'add_new_member') {
        const response = await dispatch(home_add_members(memberData));
        if (response?.payload?.status === true) {
          setSelectedSubOptions([]);
          setModalIsOpen(false);
          setShowEr(false);
          dispatch(get_home_assign_members(search));
          dispatch(member_without_assign_home(id))
        }
      } else {
        const response = await dispatch(home_add_users(userData));
        if (response?.payload?.status === true) {
          setSelectedSubOptions([]);
          setModalIsOpen(false);
          setShowEr(false);
          dispatch(get_home_assign_users(search));
          dispatch(user_without_assign_home(id))
        }
      }
    }
    return false;
  }
  const closeModal = () => {
    setShowEr(false)
    setModalIsOpen(false)
    setSelectedSubOptions([])
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customModalStyles}
      contentLabel="Create Role Modal"
    >
      <div className="modal-dialog modal-lg communication_edit_modal">
        <div className="modal-content">
          <div className="modal-header close-deactivate">
            <button type="button" className="btn-close" onClick={() => closeModal()}></button>
          </div>
          <div className="modal-body">
            <div className="font-18 hd-title">Add {type == 'add_new_member' ? 'member(s)' : 'user(s)'}?</div>
            <div className="hd-subtitle font-14">Are you interested in expanding your team and adding more {type == 'add_new_member' ? 'member' : 'user'} to your home?</div>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12 mb-3 mt-5 ">
                <div className="form-group">
                  <Controller name="assignUser" control={control} defaultValue={[]} render={({ field }) => (
                    <Select options={type == 'add_new_member' ? memberListData : userListData} className=""
                      placeholder={`${type == 'add_new_member' ? 'Select member' : 'Select user'}`}
                      isMulti = {false}
                      {...field}
                      // menuIsOpen
                      value={selectedSubOptions}
                      onChange={(option) => handleUserChange(option)}
                      closeMenuOnSelect={true}
                      components={{
                        MultiValue: () => null,
                        MultiValueLabel: () => null,
                        MultiValueRemove: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  )}
                  />
                  {/* {selectedSubOptions.length > 0 && (
                    <div className="col-md-12 mb-5">
                      <div className="home-user-sec">
                        {selectedSubOptions.map(option => (
                          <button type="button" className="btn btn-light home-users" key={option.value}>
                            {option.label}
                            <span onClick={() => handleRemoveOption(option)}>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  )} */}
                  {showEr && (<span className="error">Please select {type == 'add_new_member' ? 'member(s)' : 'user(s)'}</span>)}
                </div>
              </div>
              <div className="pop-action-btn">
                <button type="button" className="btn btn-light" onClick={() => closeModal()}>Cancel</button>
                <button type="submit" className="btn btn-danger mx-2" >{type == 'add_new_member' ?'Save members':'Save users'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Add_new_home_user
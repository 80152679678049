import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getCountry, getState } from '../../../redux/services/common';
import { useDispatch, useSelector } from 'react-redux';
import { Update_organization_detail } from '../../../redux/services/AuthSettings';
import defaultImg from "../../../Images/defaultImg.png";
import { getAuthSettingDetail } from '../../../redux/services/AuthSettings';
const Organization = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset } = useForm();
    const [countryId, setCountryID] = useState('');
    const [state, setState] = useState('');
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('')
    const inputFile = useRef(null);
    //get organization setting data
    const OrganizationDetail = useSelector(state => state?.AuthSettings?.settingInfo?.organization);
    useEffect(() => {
        setImageUrl(OrganizationDetail?.profile_img_org)
        setCountryID(OrganizationDetail?.country_id)
        // setValue('organization_hq', OrganizationDetail?.organization_hq ? OrganizationDetail?.organization_hq : '-');
        setValue('organization_name', OrganizationDetail?.organization_name);
        setValue('address1', OrganizationDetail?.address1);
        setValue('zip_code', OrganizationDetail?.zip_code);
        setValue('phone', OrganizationDetail?.phone);
        setValue('email_address', OrganizationDetail?.email_address);
        setValue('website', OrganizationDetail?.website !== 'null' ? OrganizationDetail?.website : ' ');
        setValue('City', OrganizationDetail?.City);
        setValue('country_id', OrganizationDetail?.country_id);
        setTimeout(() => {
            setValue('State_id', (OrganizationDetail?.State_id));
        }, 1000)
    }, [OrganizationDetail])
    const handleStateChange = (e) => {
        setState(e);
        setValue('City', '')
    }
    const handleCountryChange =(e)=>{
        setCountryID(e.target.value);
         setValue('City', '');
          setValue('State_id', '');
           setState('')
    }
    //image
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const selectedFile = files[0];
            setFile(selectedFile);
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
            const newImageUrl = URL.createObjectURL(selectedFile);
            setImageUrl(newImageUrl);
        }
    }
    const handleDelete = () => {
        setFile(null);
        setImageUrl('');
        if (inputFile.current) {
            inputFile.current.value = null;
        }
    };
    // country state data
    useEffect(() => {
        dispatch(getCountry());
        if (countryId) {
            dispatch(getState(countryId));
        }
    }, [countryId]);
    const CountryData = useSelector(state => state?.common?.CountryData);
    const stateData = useSelector(state => state?.common?.StateData);


    //on submit data
    const onSubmit = async () => {
        const form = new FormData();
        form.append("profile_img_org", file ? file : (typeof imageUrl !== 'object' ? imageUrl : null));
        form.append("organization_name", getValues('organization_name'));
        // form.append("organization_hq", getValues('organization_hq'));
        form.append("address1", getValues('address1'));
        form.append("zip_code", getValues('zip_code'));
        form.append("phone", getValues('phone'));
        form.append("email_address", getValues('email_address'));
        form.append("website", getValues('website'));
        form.append("City", getValues('City'));
        form.append("country_id", getValues('country_id'));
        form.append("State_id", getValues('State_id'));
        try {
            const response = await dispatch(Update_organization_detail(form));
            if (response?.payload?.status) {
                dispatch(getAuthSettingDetail());
                // navigate("/auth/settings");
            }
        } catch (error) {
            // Handle error if dispatch fails
            console.error("Error during signup:", error);
        }
    }

    const sortedCountryData = CountryData ? 
    [CountryData.find(elem => elem.iso2 === "US"), 
    ...CountryData.filter(elem => elem.iso2 !== "US")] 
    : [];
    return (
        // <div className="tab-pane fade" id="contact" role="tabpanel"
        //     aria-labelledby="contact-tab" >
<>
            <div className="font-20 py-3" id="organization">Organizational information</div>
            <div className="saf-img-upload">
                <div className="saf-img-block"><img height="100px" width="100px" src={imageUrl && imageUrl.length > 0 ? imageUrl : defaultImg} alt="defaultImg.png" /></div>
                <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                <button type="button" className="btn btn-light" onClick={onButtonClick}>Update profile
                    photo</button>
                {imageUrl ? <button type="button" className="btn btn-light" onClick={() => handleDelete()}>Delete</button> : ''}
            </div>
            <form action="" className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Organization name*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Organization Name" {...register('organization_name', { required: "true" })} />
                    {errors.organization_name && errors.organization_name.type === "required" && <span className="error">Organization name is required!</span>}
                </div>
                {/* <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Organization HQ</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Organization HQ" {...register('organization_hq', { required: "true" })} />
                    {errors.organization_hq && errors.organization_hq.type === "required" && <span className="error">Organization HQ is required!</span>}
                </div> */}
                <div className="col-md-6 col-12 mb-3">
                    <label className="form-label">Country*</label>
                    <select className="form-select" aria-label="Default select example"  {...register('country_id', { required: 'Counrty is required' })} onChange={(e) => (handleCountryChange(e))} >
                        <option value="">Select country</option>
                        {sortedCountryData && sortedCountryData?.map((elem) => {
                            return <option value={elem?.id}>{elem?.country}</option>
                        })}
                    </select>
                    {
                        countryId === '' ?
                            errors.country_id && errors.country_id.type === "required" && <span className="error">Counrty is required!</span>
                            : ''
                    }
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">State*</label>
                    <select className="form-select" aria-label="Default select example" name='State_id' {...register('State_id', { required: 'true' })} onChange={(e) => (handleStateChange(e.target.value))}>
                        <option value="">Select state</option>
                        {stateData && stateData?.map((elem) => {
                            return <option value={elem?.id}>{elem?.address_state}</option>
                        })}
                    </select>
                    {
                        state === '' ?
                            errors.State_id && errors.State_id.type === "required" && <span className="error">State is required!</span> : ''
                    }

                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">City*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="City" {...register('City', { required: "true" })} />
                    {errors.City && errors.City.type === "required" && <span className="error">City is required!</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Address*</label>
                    <input type="text" className="form-control" id="" placeholder="Address" {...register('address1', { required: "true" })} />
                    {errors.address1 && errors.address1.type === "required" && <span className="error">Address is required!</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Zip Code*</label>
                    <input type="text" className="form-control" placeholder="Zip code"
                            onKeyPress={(event) => {
                              // Allow only numbers (0-9) when typing
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={5}
                            onPaste={(event) => {
                              // Prevent pasting any non-numeric characters
                              const pastedData = event.clipboardData.getData('text');
                              if (!/^\d{1,5}$/.test(pastedData)) {
                                event.preventDefault();
                              }
                            }}
                            {...register('zip_code', {
                              pattern: {
                                value: /^\d{5}$/,
                                message: 'ZIP code must be exactly 5 digits',
                              },
                            })}
                          />
                    {errors.zip_code && errors.zip_code.type === "required" && <span className="error">Zip code is required!</span>}
                    {errors.zip_code && errors.zip_code.type === "pattern" && <span className="error">{errors?.zip_code.type?.message}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Organization email*</label>
                    <input type="text" className="form-control" id=""
                        placeholder="Email address" {...register('email_address', {
                            required: "true", pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address'
                            }
                        })} />
                    {errors.email_address && errors.email_address.type === "required" && <span className="error">Organization email is required!</span>}
                    {errors.email_address && errors.email_address.type === "pattern" && <span className="error">{errors?.email_address?.message}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Website</label>
                    <input type="text" className="form-control" id=""
                        placeholder="Website" {...register('website')} />
                    {/* {errors.website && errors.website.type === "pattern" && <span className="error">Enter the valid url</span>} */}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Phone number</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Phone Number" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={15}  {...register('phone', {
                            pattern: {
                                value: /^\d{10,15}$/,
                                message: 'Phone number must be 10 to 15 digits'
                            }
                        })}
                        onPaste={(event) => {
                            // Prevent pasting any non-numeric characters
                            const pastedData = event.clipboardData.getData('text');
                            if (!/^\d{1,5}$/.test(pastedData)) {
                                event.preventDefault();
                            }
                        }} />

                    {/* {errors.phone && errors.phone.type === "required" && <span className="error">Phone number is required!</span>} */}
                    {errors.phone && errors.phone.type === "pattern" && <span className="error">{errors?.phone?.message}</span>}
                </div>
                <div className="col-md-12 login-btn">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>

            </form>
            </>
        // </div>
    )
}

export default Organization
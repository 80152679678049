import { createSlice } from "@reduxjs/toolkit";
import {
    Activate_homes, Add_Home, gat_all_homes, edit_home, update_home, Activate_home, Deactivate_all_homes,
    Delete_homes, get_home_assign_users, user_without_assign_home, destroy_user_from_home, home_add_users,
    home_users_destroy_list,homes_list_users_not_assign,user_transafer_to_home,export_excel_file_home,CheckEmailPhone_home,
    get_home_assign_members,Activate_members,destroy_member_from_home,member_without_assign_home,home_add_members, get_loggedin_user_homes
} from "../services/AuthHomes";

const initialState = {
    isLoading: false,
    error: null,
    ActivateHomes: [],
    AllHomes: [],
    EditHomeData: [],
    GetHomeAssignUsers: [],
    GetHomeWithoutAssignUsers: [],
    GetHomeAssignMembers:[],
    ActivateMembers: [],
    MemberWithoutAssignHome: [],
    LoggedinUserHomes:[]
}
const AuthHomes = createSlice({
    name: 'AuthHomes',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // ==========Create Home  ============== //
        builder.addCase(Add_Home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_Home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_Home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });

        // ==========activate Homes ============== //
        builder.addCase(Activate_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Activate_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ActivateHomes = action?.payload?.data
        });
        builder.addCase(Activate_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get all homes============== //
        builder.addCase(gat_all_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(gat_all_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllHomes = action?.payload?.data
        });
        builder.addCase(gat_all_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit home data============== //
        builder.addCase(edit_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditHomeData = action?.payload?.data
        });
        builder.addCase(edit_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========update home ============== //
        builder.addCase(update_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(update_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(update_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========activate User ============== //
        builder.addCase(Activate_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Activate_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Activate_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========deactivate all homes ============== //
        builder.addCase(Deactivate_all_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Deactivate_all_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Deactivate_all_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========deactivate homes ============== //
        builder.addCase(Delete_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Delete_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Delete_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get home assign users============== //
        builder.addCase(get_home_assign_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_home_assign_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.GetHomeAssignUsers = action?.payload?.data
        });
        builder.addCase(get_home_assign_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get user without assign home============== //
        builder.addCase(user_without_assign_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(user_without_assign_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.GetHomeWithoutAssignUsers = action?.payload?.data
        });
        builder.addCase(user_without_assign_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========destroy user form home============== //
        builder.addCase(destroy_user_from_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(destroy_user_from_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(destroy_user_from_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========home add users (pop up)============== //
        builder.addCase(home_add_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(home_add_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(home_add_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get user destroy list============== //
        builder.addCase(home_users_destroy_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(home_users_destroy_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(home_users_destroy_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ===========get homes list users not assign============== //
          builder.addCase(homes_list_users_not_assign.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(homes_list_users_not_assign.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(homes_list_users_not_assign.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========transfer user to home============== //
         builder.addCase(user_transafer_to_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(user_transafer_to_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(user_transafer_to_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========export home excel file============== //
          builder.addCase(export_excel_file_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(export_excel_file_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(export_excel_file_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========check email phone exist============== //
          builder.addCase(CheckEmailPhone_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(CheckEmailPhone_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(CheckEmailPhone_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
           // ===========get home assign members============== //
           builder.addCase(get_home_assign_members.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_home_assign_members.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.GetHomeAssignMembers = action?.payload?.data
        });
        builder.addCase(get_home_assign_members.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========activate Members ============== //
          builder.addCase(Activate_members.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Activate_members.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ActivateMembers = action?.payload?.data
        });
        builder.addCase(Activate_members.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========destroy member form home============== //
          builder.addCase(destroy_member_from_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(destroy_member_from_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(destroy_member_from_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
           // ===========get member without assign home============== //
        builder.addCase(member_without_assign_home.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(member_without_assign_home.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MemberWithoutAssignHome = action?.payload?.data
        });
        builder.addCase(member_without_assign_home.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
            // ==========home add member (pop up)============== //
            builder.addCase(home_add_members.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            });
            builder.addCase(home_add_members.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
            });
            builder.addCase(home_add_members.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.payload;
            });
            // ==========get_loggedin_user_homes ============== //
        builder.addCase(get_loggedin_user_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_loggedin_user_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.LoggedinUserHomes = action?.payload?.data
        });
        builder.addCase(get_loggedin_user_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});

export default AuthHomes?.reducer;
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Homes from './Homes';
import Charts from './Charts';
import { get_dashboard_data } from '../../../redux/services/AuthDashboard'
import { getAuthSettingDetail } from '../../../redux/services/AuthSettings';
import StaffHomes from './StaffHomes';
import { setUserPermissions } from '../../../redux/features/commonSlice';
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(state => state?.AuthSettings?.settingInfo?.general);
  const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
  const moduleData = useSelector(state => state.common.moduleData);
  const userPermissions = useSelector(state => state.common.userPermissions);

  const [homePermission, setHomePermission] = useState([])
  const [userPermission, setUserPermission] = useState([])

  useEffect(() => {
    dispatch(get_dashboard_data())
    dispatch(getAuthSettingDetail())
  }, [])

  useEffect(() => {
    if (moduleData.length > 0) {      
      const homeModule = moduleData.find(item => item.module_name === 'Homes');
      const userModule = moduleData.find(item => item.module_name === 'Users');
      if (homeModule) {
        const permissionsArray = homeModule.permission.map(item => item.permission);
        setHomePermission(permissionsArray)
      }
      if (userModule) {
        const permissionsArray = userModule.permission.map(item => item.permission);
        setUserPermission(permissionsArray)
      }
    }
  }, [moduleData, dispatch]);

  const handleClick = (url) => {
    navigate(url)
  }


  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
              <div className="right-main-sec">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                          stroke="#667085" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>

                <div className="right-main-header search-sec">
                  <div className="left-side-content">
                    <div className="font-30 hd-title">Dashboard</div>
                    <p className="hd-subtitle">Your homes’ summary and activities </p>
                  </div>
                  {/* <div className="left-side-content searchbar">
                  <div className="form-group"><input type="text" name="" id="" className="form-control"
                    placeholder="Search" />
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                        stroke="#667085" stroke-width="1.66667" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                </div> */}
                </div>
                {/* <div className="table-btn-sec">
                <div className="btn-group progress-checkbox" role="group"
                  aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" className="btn-check" id="btncheck1" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck1">12 months</label>

                  <input type="checkbox" className="btn-check" id="btncheck2" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck2">30 days</label>

                  <input type="checkbox" className="btn-check" id="btncheck3" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck3">7 days</label>

                  <input type="checkbox" className="btn-check" id="btncheck4" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck4">24 Hours</label>
                </div>
                <button type="button" className="btn btn-light">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                      stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Filters
                </button>
              </div>
              <div className="charts">
              <BarChart />
              </div> */}

                {/* admin and owner view */}
                {currentUser && (currentUser?.type === 'Owner' || currentUserRole === 'admin') &&
                  <>
                    <Charts />
                    {homePermission?.includes("Add") || userPermission?.includes("Add") ?
                    <div className='row'>
                      {homePermission?.includes("Add") &&
                      <div className="col-md-6 col-12">
                        <div className="home-staff-create graph-content" onClick={() => handleClick('/auth/add-home')}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.12602 14.0002C8.57006 15.7255 10.1362 17.0002 12 17.0002C13.8638 17.0002 15.4299 15.7255 15.874 14.0002M11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.07989 21.0002 6.2 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424Z"
                              stroke="#0A0D14" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <div className="create-contnt">
                            <div className="font-16 hd-title">Create new home</div>
                            <div className="hd-subtitle">Then add staff and members</div>
                          </div>
                        </div>
                      </div>
                      }
                      {userPermission?.includes("Add") &&
                      <div className="col-md-6 col-12">
                        <div className="home-staff-create graph-content" onClick={() => handleClick('/auth/users-add')}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
                              stroke="#344054" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <div className="create-contnt">
                            <div className="font-16 hd-title">Add new staff</div>
                            <div className="hd-subtitle">Before assigning roles and members</div>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                    :
                    null
                    }
                    <Homes />
                  </>
                }

                {/* staff view */}

                {currentUser && (currentUser?.type !== 'Owner' && currentUserRole !== 'admin') &&
                  <>
                    <div className='row'>
                      <div className="col-md-4 col-12">
                        <div className="home-staff-create graph-content" onClick={() => handleClick('/auth/communication')}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 10.5H7.51M12 10.5H12.01M16.5 10.5H16.51M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18ZM8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5ZM12.5 10.5C12.5 10.7761 12.2761 11 12 11C11.7239 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.7239 10 12 10C12.2761 10 12.5 10.2239 12.5 10.5ZM17 10.5C17 10.7761 16.7761 11 16.5 11C16.2239 11 16 10.7761 16 10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          <div className="create-contnt">
                            <div className="font-16 hd-title">Add communication</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="home-staff-create graph-content" onClick={() => handleClick('/auth/appointment')}>
                          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          <div className="create-contnt">
                            <div className="font-16 hd-title">Add an Appointment</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="home-staff-create graph-content" onClick={() => handleClick('/auth/mar')}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 2.6C13 2.03995 13 1.75992 12.891 1.54601C12.7951 1.35785 12.6422 1.20487 12.454 1.10899C12.2401 1 11.9601 1 11.4 1H8.6C8.03995 1 7.75992 1 7.54601 1.10899C7.35785 1.20487 7.20487 1.35785 7.10899 1.54601C7 1.75992 7 2.03995 7 2.6V5.4C7 5.96005 7 6.24008 6.89101 6.45399C6.79513 6.64215 6.64215 6.79513 6.45399 6.89101C6.24008 7 5.96005 7 5.4 7H2.6C2.03995 7 1.75992 7 1.54601 7.10899C1.35785 7.20487 1.20487 7.35785 1.10899 7.54601C1 7.75992 1 8.03995 1 8.6V11.4C1 11.9601 1 12.2401 1.10899 12.454C1.20487 12.6422 1.35785 12.7951 1.54601 12.891C1.75992 13 2.03995 13 2.6 13H5.4C5.96005 13 6.24008 13 6.45399 13.109C6.64215 13.2049 6.79513 13.3578 6.89101 13.546C7 13.7599 7 14.0399 7 14.6V17.4C7 17.9601 7 18.2401 7.10899 18.454C7.20487 18.6422 7.35785 18.7951 7.54601 18.891C7.75992 19 8.03995 19 8.6 19H11.4C11.9601 19 12.2401 19 12.454 18.891C12.6422 18.7951 12.7951 18.6422 12.891 18.454C13 18.2401 13 17.9601 13 17.4V14.6C13 14.0399 13 13.7599 13.109 13.546C13.2049 13.3578 13.3578 13.2049 13.546 13.109C13.7599 13 14.0399 13 14.6 13H17.4C17.9601 13 18.2401 13 18.454 12.891C18.6422 12.7951 18.7951 12.6422 18.891 12.454C19 12.2401 19 11.9601 19 11.4V8.6C19 8.03995 19 7.75992 18.891 7.54601C18.7951 7.35785 18.6422 7.20487 18.454 7.10899C18.2401 7 17.9601 7 17.4 7L14.6 7C14.0399 7 13.7599 7 13.546 6.89101C13.3578 6.79513 13.2049 6.64215 13.109 6.45399C13 6.24008 13 5.96005 13 5.4V2.6Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          <div className="create-contnt">
                            <div className="font-16 hd-title">View MAR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <StaffHomes />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Dashboard
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

const StaffHeader = ({ search, setSearch, handleSearchBar }) => {

    const homeslist = useSelector(state => state?.AuthHomes?.LoggedinUserHomes);
    const [showFilter, setShowFilter] = useState(false);

    const handleHomeChange = (e) => {
        setSearch({
          ...search,
          page : 1,
          home: e.target.value
        });
      };
      
    return (
        <>
            <div className="data-table-custom">
                <div className="graph-header">
                    <div className="font-18 hd-title">Homes</div>
                </div>
            </div>

            {
                !showFilter ?
                    <div className="table-btn-sec mem_filt_btn">
                        <div className="form-group searchbar"><input type="text" name="" id="" className="form-control" placeholder="Search name, ahss id" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <button type="button" className="btn btn-light" onClick={() => setShowFilter(true)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            More Filters
                        </button>
                    </div> : ''
            }
            {showFilter ?
                <div className="table-btn-sec more-filter">
                    <button type="button" className="btn btn-light" onClick={() => (setSearch({ ...search, role: '', status: '', searchBar: '', home: '' }), setShowFilter(false))}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Close Filters
                    </button>
                    <button className='clear-filter' onClick={() => setSearch({ ...search, role: '', status: '', searchBar: '', home: '' })}>Clear all filters</button>
                    <div className="filter-option">
                        <div className="form-group searchbar">
                            <label className="form-label">Search</label>
                            <input type="text" name="" id="" className="form-control" placeholder="Search name,ahss id" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Home</label>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => handleHomeChange(e)} value={search?.home}>
                                <option value=''>Select Home</option>
                                {homeslist && homeslist?.map((elem) => {
                                    return <option value={elem?.id}>{elem?.home_name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div> : ''
            }
        </>
    )
}
export default StaffHeader
import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import commonSlice from "./commonSlice";
import owner from "./owner";
import subscription from "./subscription";
import AuthSettings from './AuthSettingSlice';
import  AuthUsers  from "./AuthUserSlice";
import AuthHomes from "./AuthHomesSlice";
import AuthMember from './AuthMembersSlice'
import AuthMar from './AuthMarSlice'
import AuthCommunication from './AuthCommunicationSlice'
import AuthDocumentsSlice from "./AuthDocumentsSlice";
import AuthAppointmentSlice from "./AuthAppointmentSlice";
import AuthDashboardSlice from "./AuthDashboardSlice"
import AuthTasks from "./AuthTasksSlice"
import AuthNotificationSlice from './AuthNotificationSlice'
const rootReducer= combineReducers({
    auth: authSlice,
    suscription: subscription,
    owner: owner,
    common:commonSlice,
    AuthSettings:AuthSettings,
    AuthUsers:AuthUsers,
    AuthHomes:AuthHomes,
    AuthMember:AuthMember,
    AuthMar:AuthMar,
    AuthCommunication:AuthCommunication,
    AuthDocuments: AuthDocumentsSlice,
    AuthAppointment: AuthAppointmentSlice,
    AuthDashboard: AuthDashboardSlice,
    AuthTasks: AuthTasks,
    AuthNotification: AuthNotificationSlice
});
export default rootReducer;
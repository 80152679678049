import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import General from './General';
import Organization from './Organization';
import Roles from './Roles-Permissions/All';
import LicenseInfo from './AuthSubscription/LicenseInfo';
import { getAuthSettingDetail } from '../../../redux/services/AuthSettings';
import { Link } from 'react-router-dom';

const Setting = () => {
    const dispatch = useDispatch();

    const userType = useSelector(state => state?.AuthSettings?.settingInfo?.general?.type);


    const [tabs, setTabs] = useState(localStorage.getItem('tabs') || 'organization')


    useEffect(() => {
        dispatch(getAuthSettingDetail());
    }, [dispatch]);

    useEffect(()=>{
        if(userType !== 'Owner'){
            setTabs('owner')
        }
    },[userType])

    const handleTabChange = (tabId) => {
        setTabs(tabId)
        localStorage.setItem('tabs', tabId)
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="right-main-sec">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/auth/dashboard">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                                                    stroke="#667085" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ol>
                                <div className="right-main-header">
                                    <h2>Settings</h2>
                                </div>
                                <div className="setting-tabs">
                                        {userType === 'Owner' 
                                        ? 
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tabs === 'organization' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('organization')} >Organization</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tabs === 'owner' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('owner')} id="owner" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true">Owner information</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tabs === 'roles' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('roles')}>Roles and Permissions</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tabs === 'billings' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('billings')}>Billings and
                                                plans</button>
                                        </li>
                                    </ul>
                                    : 
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className='nav-link active'
                                                onClick={() => handleTabChange('owner')} id="owner" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true">General information</button>
                                        </li>
                                    </ul>
                                    }
                                    <div className="tab-content" id="myTabContent">
                                        {tabs === 'owner' && <General />}
                                        {/* {tabs === 'security' && <Security />} */}
                                        {tabs === 'organization' && <Organization />}
                                        {tabs === 'roles' && <Roles />}
                                        {tabs === 'billings' && <LicenseInfo />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Setting
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import StaffHeader from './StaffHeader';
import { get_all_members } from '../../../redux/services/AuthMembers';
import { get_loggedin_user_homes } from '../../../redux/services/AuthHomes';


const StaffHomes = () => {
    const dispatch = useDispatch();
    // Extract members and totalPages from state
    const memberList = useSelector(state => state?.AuthMember?.AllMemberList?.members);
    const totalPages = useSelector(state => state?.AuthMember?.AllMemberList?.totalPages);  // Make sure you're extracting totalPages correctly

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState({
        role: '',
        status: '',
        page: 1,
        listData: 10,
        home: '',
        searchBar: ''
    });

    // Sort state: keeps track of sorting property and order (ascending/descending)
    const [sort, setSort] = useState('asc');

    useEffect(() => {
        dispatch(get_loggedin_user_homes());
        dispatch(get_all_members(search));
    }, [search, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearch({
            ...search,
            page: pageNumber
        });
    };

    const handleSearchBar = (e) => {
        setCurrentPage(1);
        setSearch({
            ...search,
            page: 1,
            searchBar: e.target.value,
        });
    };

    const handleSorting = () => {
        // Toggle the sorting order (ascending <-> descending)
        const newOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newOrder);
    };

    // Function to sort the members based on the selected property and order
    const sortMembers = (members) => {
        return [...members].sort((a, b) => {
            let valueA = a.first_name.toLowerCase();
            let valueB = b.first_name.toLowerCase();

            if (sort === 'asc') {
                return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
            } else {
                return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
            }
        });
    };

    const sortedMembers = memberList ? sortMembers(memberList) : [];
    
    return (
        <div className="row">
            <div className="col-md-12 col-12 mt-4">
                <StaffHeader search={search} setSearch={setSearch} handleSearchBar={handleSearchBar} />
                <div className="table-sec">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">Name</label>
                                            <span className={`sort_icon ${sort}`} onClick={() => handleSorting()}>
                                                <svg className="ms-1" width="12" height="12"
                                                    viewBox="0 0 12 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                        stroke="#475467" strokeWidth="0.888889"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">Group home</th>
                                    <th scope="col">AHSS ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedMembers && sortedMembers.map((item) => {
                                    let imageName = item?.first_name?.charAt(0)?.toUpperCase() + item?.last_name?.charAt(0)?.toUpperCase() || '';
                                    let fullName = item?.first_name?.toUpperCase() + ' ' + item?.last_name?.toUpperCase();
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {
                                                    <div className='td-form-check'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="" id="flexCheckIndeterminate"
                                                            />
                                                        </div>
                                                        < div className="profile-sec" >
                                                            <span className="name ">
                                                                {item?.profile_pic ?
                                                                    <img src={item?.profile_pic} /> :
                                                                    imageName
                                                                }
                                                            </span>
                                                            <div
                                                                className="font-14 hd-title"
                                                            >
                                                                {fullName.trim() !== '' ? fullName : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                            <td>{item?.home?.home_name}</td>
                                            <td className='td_upperCase'>{item.ahss_id}</td>
                                        </tr>
                                    );
                                })}

                                <tr>
                                    <td colSpan="7">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}  /* Pass the correct number of totalPages here */
                                            onPageChange={handlePageChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffHomes;

import React from 'react';

export const MemberSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path d="M31.9957 36.2328C26.2451 36.2328 21.3331 37.1395 21.3331 40.7661C21.3331 44.3941 26.2144 45.3328 31.9957 45.3328C37.7464 45.3328 42.6584 44.4275 42.6584 40.7995C42.6584 37.1715 37.7784 36.2328 31.9957 36.2328Z" fill="#2342BF"/>
<path opacity="0.4" d="M31.9958 32.7783C35.9131 32.7783 39.0518 29.6383 39.0518 25.7223C39.0518 21.8063 35.9131 18.6663 31.9958 18.6663C28.0798 18.6663 24.9398 21.8063 24.9398 25.7223C24.9398 29.6383 28.0798 32.7783 31.9958 32.7783Z" fill="#2342BF"/>
</svg>
);

export const HomeSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path d="M28.1916 43.7095V39.6203C28.1916 38.5841 29.0342 37.7423 30.0779 37.7358H33.9101C34.9585 37.7358 35.8083 38.5795 35.8083 39.6203V43.6976C35.8083 44.5964 36.5387 45.3268 37.4439 45.3333H40.0584C41.2795 45.3364 42.4516 44.8571 43.3162 44.0009C44.1808 43.1448 44.6666 41.9823 44.6666 40.77V29.1545C44.6666 28.1752 44.2294 27.2463 43.4728 26.618L34.5906 19.5657C33.038 18.3322 30.8205 18.372 29.3138 19.6605L20.6227 26.618C19.8303 27.2278 19.3567 28.1594 19.3333 29.1545V40.7582C19.3333 43.2849 21.3965 45.3333 23.9415 45.3333H26.4964C26.9322 45.3365 27.3513 45.1668 27.6606 44.8619C27.9699 44.557 28.1439 44.1422 28.1439 43.7095H28.1916Z" fill="#2342BF"/>
</svg>

);

export const AppointmentSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 38.4933V28.3427H44V38.5747C44 42.76 41.3655 45.3333 37.1504 45.3333H26.8364C22.6608 45.3333 20 42.7067 20 38.4933ZM26.6125 35.2133C26.0066 35.2413 25.506 34.76 25.4797 34.148C25.4797 33.5347 25.9539 33.028 26.5598 33C27.1526 33 27.64 33.468 27.6531 34.0667C27.6795 34.6813 27.2053 35.188 26.6125 35.2133ZM32.0263 35.2133C31.4204 35.2413 30.9199 34.76 30.8935 34.148C30.8935 33.5347 31.3677 33.028 31.9737 33C32.5664 33 33.0538 33.468 33.067 34.0667C33.0933 34.6813 32.6191 35.188 32.0263 35.2133ZM37.4007 40.12C36.7947 40.1067 36.3074 39.6 36.3074 38.9867C36.2942 38.3733 36.7816 37.868 37.3875 37.8547H37.4007C38.0198 37.8547 38.5203 38.3613 38.5203 38.9867C38.5203 39.6133 38.0198 40.12 37.4007 40.12ZM30.8935 38.9867C30.9199 39.6 31.4204 40.0813 32.0263 40.0533C32.6191 40.028 33.0933 39.5213 33.067 38.908C33.0538 38.308 32.5664 37.84 31.9737 37.84C31.3677 37.868 30.8935 38.3733 30.8935 38.9867ZM25.4665 38.9867C25.4929 39.6 25.9934 40.0813 26.5993 40.0533C27.1921 40.028 27.6663 39.5213 27.64 38.908C27.6268 38.308 27.1394 37.84 26.5467 37.84C25.9407 37.868 25.4665 38.3733 25.4665 38.9867ZM36.3205 34.1347C36.3205 33.5213 36.7947 33.028 37.4007 33.0147C37.9934 33.0147 38.4676 33.4933 38.494 34.0813C38.5071 34.6947 38.0329 35.2013 37.4402 35.2133C36.8342 35.2267 36.3337 34.76 36.3205 34.148V34.1347Z" fill="#2342BF"/>
<path opacity="0.4" d="M20.0045 28.3425C20.0216 27.5599 20.0874 26.0065 20.2113 25.5065C20.8435 22.6945 22.9906 20.9079 26.0598 20.6532H37.9412C40.984 20.9212 43.1575 22.7199 43.7897 25.5065C43.9122 25.9932 43.9781 27.5585 43.9952 28.3425H20.0045Z" fill="#2342BF"/>
<path d="M27.0732 24.7867C27.6528 24.7867 28.0874 24.348 28.0874 23.76V19.6947C28.0874 19.1067 27.6528 18.6667 27.0732 18.6667C26.4936 18.6667 26.0589 19.1067 26.0589 19.6947V23.76C26.0589 24.348 26.4936 24.7867 27.0732 24.7867Z" fill="#2342BF"/>
<path d="M36.9266 24.7867C37.493 24.7867 37.9408 24.348 37.9408 23.76V19.6947C37.9408 19.1067 37.493 18.6667 36.9266 18.6667C36.347 18.6667 35.9123 19.1067 35.9123 19.6947V23.76C35.9123 24.348 36.347 24.7867 36.9266 24.7867Z" fill="#2342BF"/>
</svg>

);

export const CommunicationSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path d="M31.9957 36.2328C26.2451 36.2328 21.3331 37.1395 21.3331 40.7661C21.3331 44.3941 26.2144 45.3328 31.9957 45.3328C37.7464 45.3328 42.6584 44.4275 42.6584 40.7995C42.6584 37.1715 37.7784 36.2328 31.9957 36.2328Z" fill="#2342BF"/>
<path opacity="0.4" d="M31.9958 32.7783C35.9131 32.7783 39.0518 29.6383 39.0518 25.7223C39.0518 21.8063 35.9131 18.6663 31.9958 18.6663C28.0798 18.6663 24.9398 21.8063 24.9398 25.7223C24.9398 29.6383 28.0798 32.7783 31.9958 32.7783Z" fill="#2342BF"/>
</svg>
);

export const MARSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path d="M31.9957 36.2328C26.2451 36.2328 21.3331 37.1395 21.3331 40.7661C21.3331 44.3941 26.2144 45.3328 31.9957 45.3328C37.7464 45.3328 42.6584 44.4275 42.6584 40.7995C42.6584 37.1715 37.7784 36.2328 31.9957 36.2328Z" fill="#2342BF"/>
<path opacity="0.4" d="M31.9958 32.7783C35.9131 32.7783 39.0518 29.6383 39.0518 25.7223C39.0518 21.8063 35.9131 18.6663 31.9958 18.6663C28.0798 18.6663 24.9398 21.8063 24.9398 25.7223C24.9398 29.6383 28.0798 32.7783 31.9958 32.7783Z" fill="#2342BF"/>
</svg>
);

export const TaskSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
<path d="M37.7871 18.6664H26.2271C21.7071 18.6664 18.6671 21.8397 18.6671 26.5597V37.453C18.6671 42.1597 21.7071 45.333 26.2271 45.333H37.7871C42.3071 45.333 45.3337 42.1597 45.3337 37.453V26.5597C45.3337 21.8397 42.3071 18.6664 37.7871 18.6664Z" fill="#2342BF"/>
<path d="M30.4183 36.3305C30.1196 36.3305 29.821 36.2172 29.593 35.9892L26.429 32.8252C25.973 32.3692 25.973 31.6305 26.429 31.1759C26.885 30.7199 27.6223 30.7185 28.0783 31.1745L30.4183 33.5145L35.9223 28.0105C36.3783 27.5545 37.1156 27.5545 37.5716 28.0105C38.0276 28.4665 38.0276 29.2052 37.5716 29.6612L31.2436 35.9892C31.0156 36.2172 30.717 36.3305 30.4183 36.3305Z" fill="white"/>
</svg>

);

export const RoleSvg = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#EFF4FF"/>
    <path opacity="0.4" d="M29.4444 37.2773H20.677C19.5674 37.2773 18.6667 38.1622 18.6667 39.2524C18.6667 40.3411 19.5674 41.2275 20.677 41.2275H29.4444C30.554 41.2275 31.4548 40.3411 31.4548 39.2524C31.4548 38.1622 30.554 37.2773 29.4444 37.2773Z" fill="#2342BF"/>
    <path opacity="0.4" d="M45.3333 24.5048C45.3333 23.4161 44.4326 22.5311 43.3245 22.5311H34.5571C33.4475 22.5311 32.5468 23.4161 32.5468 24.5048C32.5468 25.5949 33.4475 26.4799 34.5571 26.4799H43.3245C44.4326 26.4799 45.3333 25.5949 45.3333 24.5048Z" fill="#2342BF"/>
    <path d="M27.837 24.5047C27.837 26.9936 25.7852 29.011 23.2518 29.011C20.72 29.011 18.6667 26.9936 18.6667 24.5047C18.6667 22.0173 20.72 20 23.2518 20C25.7852 20 27.837 22.0173 27.837 24.5047Z" fill="#2342BF"/>
    <path d="M45.3333 39.199C45.3333 41.6864 43.2815 43.7037 40.7482 43.7037C38.2163 43.7037 36.163 41.6864 36.163 39.199C36.163 36.7101 38.2163 34.6927 40.7482 34.6927C43.2815 34.6927 45.3333 36.7101 45.3333 39.199Z" fill="#2342BF"/>
    </svg>
    
);
import React from 'react';

export const PlusUrlSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 2.83333C11.5 2.36662 11.5 2.13327 11.4092 1.95501C11.3293 1.79821 11.2018 1.67072 11.045 1.59083C10.8667 1.5 10.6334 1.5 10.1667 1.5H7.83333C7.36662 1.5 7.13327 1.5 6.95501 1.59083C6.79821 1.67072 6.67072 1.79821 6.59083 1.95501C6.5 2.13327 6.5 2.36662 6.5 2.83333V5.16667C6.5 5.63338 6.5 5.86673 6.40917 6.04499C6.32928 6.20179 6.20179 6.32928 6.04499 6.40917C5.86673 6.5 5.63338 6.5 5.16667 6.5H2.83333C2.36662 6.5 2.13327 6.5 1.95501 6.59083C1.79821 6.67072 1.67072 6.79821 1.59083 6.95501C1.5 7.13327 1.5 7.36662 1.5 7.83333V10.1667C1.5 10.6334 1.5 10.8667 1.59083 11.045C1.67072 11.2018 1.79821 11.3293 1.95501 11.4092C2.13327 11.5 2.36662 11.5 2.83333 11.5H5.16667C5.63338 11.5 5.86673 11.5 6.04499 11.5908C6.20179 11.6707 6.32928 11.7982 6.40917 11.955C6.5 12.1333 6.5 12.3666 6.5 12.8333V15.1667C6.5 15.6334 6.5 15.8667 6.59083 16.045C6.67072 16.2018 6.79821 16.3293 6.95501 16.4092C7.13327 16.5 7.36662 16.5 7.83333 16.5H10.1667C10.6334 16.5 10.8667 16.5 11.045 16.4092C11.2018 16.3293 11.3293 16.2018 11.4092 16.045C11.5 15.8667 11.5 15.6334 11.5 15.1667V12.8333C11.5 12.3666 11.5 12.1333 11.5908 11.955C11.6707 11.7982 11.7982 11.6707 11.955 11.5908C12.1333 11.5 12.3666 11.5 12.8333 11.5H15.1667C15.6334 11.5 15.8667 11.5 16.045 11.4092C16.2018 11.3293 16.3293 11.2018 16.4092 11.045C16.5 10.8667 16.5 10.6334 16.5 10.1667V7.83333C16.5 7.36662 16.5 7.13327 16.4092 6.95501C16.3293 6.79821 16.2018 6.67072 16.045 6.59083C15.8667 6.5 15.6334 6.5 15.1667 6.5L12.8333 6.5C12.3666 6.5 12.1333 6.5 11.955 6.40917C11.7982 6.32928 11.6707 6.20179 11.5908 6.04499C11.5 5.86673 11.5 5.63338 11.5 5.16667V2.83333Z" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export const PlusSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 4.6C15 4.03995 15 3.75992 14.891 3.54601C14.7951 3.35785 14.6422 3.20487 14.454 3.10899C14.2401 3 13.9601 3 13.4 3H10.6C10.0399 3 9.75992 3 9.54601 3.10899C9.35785 3.20487 9.20487 3.35785 9.10899 3.54601C9 3.75992 9 4.03995 9 4.6V7.4C9 7.96005 9 8.24008 8.89101 8.45399C8.79513 8.64215 8.64215 8.79513 8.45399 8.89101C8.24008 9 7.96005 9 7.4 9H4.6C4.03995 9 3.75992 9 3.54601 9.10899C3.35785 9.20487 3.20487 9.35785 3.10899 9.54601C3 9.75992 3 10.0399 3 10.6V13.4C3 13.9601 3 14.2401 3.10899 14.454C3.20487 14.6422 3.35785 14.7951 3.54601 14.891C3.75992 15 4.03995 15 4.6 15H7.4C7.96005 15 8.24008 15 8.45399 15.109C8.64215 15.2049 8.79513 15.3578 8.89101 15.546C9 15.7599 9 16.0399 9 16.6V19.4C9 19.9601 9 20.2401 9.10899 20.454C9.20487 20.6422 9.35785 20.7951 9.54601 20.891C9.75992 21 10.0399 21 10.6 21H13.4C13.9601 21 14.2401 21 14.454 20.891C14.6422 20.7951 14.7951 20.6422 14.891 20.454C15 20.2401 15 19.9601 15 19.4V16.6C15 16.0399 15 15.7599 15.109 15.546C15.2049 15.3578 15.3578 15.2049 15.546 15.109C15.7599 15 16.0399 15 16.6 15H19.4C19.9601 15 20.2401 15 20.454 14.891C20.6422 14.7951 20.7951 14.6422 20.891 14.454C21 14.2401 21 13.9601 21 13.4V10.6C21 10.0399 21 9.75992 20.891 9.54601C20.7951 9.35785 20.6422 9.20487 20.454 9.10899C20.2401 9 19.9601 9 19.4 9L16.6 9C16.0399 9 15.7599 9 15.546 8.89101C15.3578 8.79513 15.2049 8.64215 15.109 8.45399C15 8.24008 15 7.96005 15 7.4V4.6Z"
            stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const ActiveSvg = () => (
    <svg className="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
            fill="white" />
        <path
            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
            stroke="#98A2B3" stroke-width="2" />
        <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#98A2B3" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
export const EditButtonSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 15L16.6666 15.9117C16.2245 16.3951 15.6251 16.6667 15.0001 16.6667C14.3751 16.6667 13.7757 16.3951 13.3337 15.9117C12.891 15.4293 12.2916 15.1584 11.6668 15.1584C11.042 15.1584 10.4426 15.4293 9.99998 15.9117M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
export const SearchSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
)
export const MoreFilterSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
)
export const HomeIconSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.12602 14C8.57006 15.7252 10.1362 17 12 17C13.8638 17 15.4299 15.7252 15.874 14M11.0177 2.764L4.23539 8.03912C3.78202 8.39175 3.55534 8.56806 3.39203 8.78886C3.24737 8.98444 3.1396 9.20478 3.07403 9.43905C3 9.70352 3 9.9907 3 10.5651V17.8C3 18.9201 3 19.4801 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4801 21 18.9201 21 17.8V10.5651C21 9.9907 21 9.70352 20.926 9.43905C20.8604 9.20478 20.7526 8.98444 20.608 8.78886C20.4447 8.56806 20.218 8.39175 19.7646 8.03913L12.9823 2.764C12.631 2.49075 12.4553 2.35412 12.2613 2.3016C12.0902 2.25526 11.9098 2.25526 11.7387 2.3016C11.5447 2.35412 11.369 2.49075 11.0177 2.764Z" stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const DeleteIconSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
            stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import { useParams } from 'react-router-dom';
import { edit_member_medication, destroy_mar } from '../../../redux/services/AuthMar';
import { EditButtonSvg, DeleteIconSvg } from '../../../Images/MarSvg';
import OverdueTab from "./Tabs/OverdueTab";
import PendingTab from "./Tabs/PendingTab";
import AdministeredTab from "./Tabs/AdministeredTab";
import UrlBar from './UrlBar'
// import IncomingTab from "./Tabs/IncomingTab";
import AddMar from "./AddMar";
import DeleteMAR from './DeleteMAR'

const TABS = ['Overdue', 'Pending', 'Administered']

const EditMar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const userType = useSelector(state => state?.AuthSettings?.settingInfo?.general?.type);
  const memberDetails = useSelector(state => state?.AuthMar?.EditMemberDetails?.member);
  const EditMemberDetails = useSelector(state => state?.AuthMar?.EditMemberDetails);
  const currentUser = useSelector(state => state?.AuthSettings?.settingInfo?.general);
  const userPermissions = useSelector(state => state.common.userPermissions);


  const [activeTab, setActiveTab] = useState('Pending');
  const [addNewModal, setAddNewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);


  useEffect(() => {
    if (id) {
      dispatch(edit_member_medication(id));
    }
  }, [dispatch, id, addNewModal]);
  
  useEffect(()=>{
    if(EditMemberDetails?.approval_request_to === currentUser?.id){
      setIsEdit(false)
    }

  },[currentUser,EditMemberDetails])

  const handleDeleteSubmit = async() => {
    let response = await dispatch(destroy_mar(id))
   if (response.payload.status){
     setDeleteModal(false)
     navigate(`/auth/mar`)
   }
  }
  let memberName = memberDetails?.first_name.charAt(0) + memberDetails?.last_name.charAt(0);

  return (
    <div id="layoutSidenav">
      <SideBar />
      <div id="layoutSidenav_content">
        <main>
          <div className="vital_member_sec pending_sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12">
                  <div className="right-main-sec">
                    <UrlBar />
                    {userPermissions?.includes('Delete') &&
                    <div className="mar_delete">
                    <button type="button" className="btn btn-danger mx-2" onClick={() => setDeleteModal(true)}>Delete</button>
                    </div>
                    }
                    <div className="mjohan_cont">
                      <div className="mavtar_img">
                        {memberDetails?.profile_pic ?
                          <img src={memberDetails?.profile_pic} />
                          :
                          <div className="name green">
                            {memberName}
                          </div>
                        }
                      </div>
                      <h4>{`${memberDetails?.first_name} ${memberDetails?.last_name}`}</h4>
                      {userPermissions?.includes('Edit') &&
                      <button type="button" className="btn btn-primary" onClick={() => setAddNewModal(isEdit)}>
                      <>
                            {isEdit ?
                              <>
                                <EditButtonSvg /> Edit Medication
                              </>
                              :
                              <>Medication</>
                            }
                          </>
                      </button>
                      }
                    </div>

                    <div className="setting-tabs">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {TABS.map(tab => (
                          <li className="nav-item" role="presentation" key={tab}>
                            <button className={`nav-link ${activeTab === tab ? 'active' : ''}`} onClick={() => setActiveTab(tab)}>
                              {tab}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        {activeTab === 'Overdue' && <OverdueTab activeTab={activeTab} id={EditMemberDetails?.id} />}
                        {activeTab === 'Pending' && <PendingTab activeTab={activeTab} id={EditMemberDetails?.id} addNewModal={addNewModal}/>}
                        {activeTab === 'Administered' && <AdministeredTab activeTab={activeTab} id={EditMemberDetails?.id} />}
                        {/* {activeTab === 'Incoming' && <IncomingTab activeTab={activeTab}/>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {addNewModal && <AddMar onModalToggle={setAddNewModal} editMemberDetails={EditMemberDetails} />}
      {deleteModal && <DeleteMAR onDeleteModal={setDeleteModal} onDeleteSubmit={handleDeleteSubmit}/>}
    </div>
  );
};

export default EditMar;

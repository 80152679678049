import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getCountry, getState } from '../../../../redux/services/common';
import { useDispatch, useSelector } from 'react-redux';
import { Update_billing_detail, getAuthSettingDetail, getPlanFeatureActive, get_customer_all_cards } from '../../../../redux/services/AuthSettings';
import Modal from 'react-modal';
import { clearStateAndPersistedData } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { BillingFrequencySvg, SavedCardsSvg, AddNewCardSvg, BillingInformationSvg, OrderSummarySvg } from '../../../../Images/UpdateSubscriptionSvgs'
import Slider from "react-slick";

const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        width: '60%',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        height: '90%',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};
const UpdateSubscription = ({ amount1, modalIsOpen, setModalIsOpen }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    var settings = {
        dots: false,                // Enable pagination dots
        infinite: false,           // Disable infinite loop to avoid repeating the card
        speed: 500,                // Animation speed
        slidesToShow: 1,           // Show one slide at a time
        slidesToScroll: 1,         // Scroll one slide at a time
        centerMode: true,         // Disable center mode (only applies to multiple items)
        focusOnSelect: true,       // Focus on the selected card
        adaptiveHeight: true,      // Adjust height to the content
    };

    const billingInfo = useSelector(state => state?.AuthSettings?.settingInfo?.billingPlan);
    const OrganizationDetail = useSelector(state => state?.AuthSettings?.settingInfo?.organization);
    //subscription plan detail
    const subscriptionPlan = useSelector(state => state?.suscription?.planInfo);
    const subscriptionPlanDiscount = useSelector(state => state?.suscription?.planDiscount);
    const CountryData = useSelector(state => state?.common?.CountryData);
    const stateData = useSelector(state => state?.common?.StateData);
    const CustomerAllCard = useSelector(state => state?.AuthSettings?.CustomerAllCard);


    const [showBillingError, setShowBillingError] = useState(false)
    const [showMsg, setShowMsg] = useState({ expiry: true, country: true })
    const [countryId, setCountryID] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(subscriptionPlan?.selectYear);
    // Update selectedPlan whenever subscriptionPlan changes
    const [planeAmount, setPlaneAmount] = useState(selectedPlan === 1 ? subscriptionPlan?.yearlyPriceWithDiscount : subscriptionPlan?.monthAmount);
    const [totalAmount, setTotalAmount] = useState(parseInt(billingInfo?.no_of_licence) * planeAmount)
    const [increasePlane, setIncreasePlan] = useState(subscriptionPlan?.increasePlane ? subscriptionPlan?.increasePlane : 1)

    const [cardNumberError, setCardNumberError] = useState(null);
    const [cvcError, setCvcError] = useState(null);
    const [expirationError, setExpirationError] = useState(null);
    const [complete, setComplete] = useState({ cardNumber: false, cvc: false, expiration: false });
    const [paymentMethodId, setPaymentMethodId] = useState('')
    const [openAccordion, setOpenAccordion] = useState(0);
    const [openSubAccordion, setOpenSubAccordion] = useState(null);
    const [cardError, setCardError] = useState('');
    const [checkStepOne, setCheckStepOne] = useState(false)
    const [checkSelectCard, setcheckSelectCard] = useState(false)

    // First form instance
    const {
        register: register1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        setValue: setValue1,
        getValues: getValues1
    } = useForm();

    // Second form instance
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        setValue: setValue2,
        getValues: getValues2
    } = useForm();

    useEffect(() => {
        dispatch(get_customer_all_cards())
    }, [])

    useEffect(() => {
        setSelectedPlan(subscriptionPlan?.selectYear);
    }, [subscriptionPlan]);

    useEffect(() => {
        setPlaneAmount(selectedPlan === 1 ? subscriptionPlan?.yearlyPriceWithDiscount : subscriptionPlan?.monthAmount);
    }, [selectedPlan, subscriptionPlan]);

    // Update totalAmount whenever selectedPlan or planeAmount changes
    useEffect(() => {
        if (selectedPlan != null && planeAmount != null && subscriptionPlan != null && billingInfo != null && subscriptionPlan?.type !== 'update') {
            const newTotalAmount = calculateTotalAmount(selectedPlan, planeAmount, subscriptionPlan, billingInfo);
            setTotalAmount(newTotalAmount);
        }
    }, [selectedPlan, planeAmount, subscriptionPlan, billingInfo]);

    useEffect(() => {
        setTotalAmount(billingInfo?.no_of_licence * planeAmount);
        setIncreasePlan(parseInt(billingInfo?.no_of_licence))
    }, [subscriptionPlan])

    //country and state api
    useEffect(() => {
        dispatch(getCountry());
        if (countryId) {
            dispatch(getState(countryId));
        }
    }, [countryId])

    const calculateTotalAmount = (selectedPlan, planeAmount, subscriptionPlan, billingInfo) => {
        if (selectedPlan === 1) {
            const yearAmount = (increasePlane - parseInt(billingInfo?.no_of_licence)) * subscriptionPlan?.yearlyPriceWithDiscount;
            return yearAmount + (parseInt(billingInfo?.no_of_licence) * planeAmount);
        } else if (selectedPlan === 0) {
            const monthAmount = (increasePlane - parseInt(billingInfo?.no_of_licence)) * subscriptionPlan?.monthAmount;
            return monthAmount + (parseInt(billingInfo?.no_of_licence) * planeAmount);
        } else {
            return parseInt(billingInfo?.no_of_licence) * planeAmount;
        }
    };

    // change the billing frequency
    const handlePlanChange = (event) => {
        const newSelectedPlan = parseInt(event.target.value);
        setSelectedPlan(newSelectedPlan);
        if (selectedPlan != null && planeAmount != null && subscriptionPlan != null && billingInfo != null) {
            const newTotalAmount = calculateTotalAmount(newSelectedPlan, planeAmount, subscriptionPlan, billingInfo);
            setTotalAmount(newTotalAmount);
        }
    };

    const onSubmit = async () => {
        const form = {
            "no_of_licence": 0,
            "total_amount": planeAmount,
            "subscription_plan_id": subscriptionPlan?.id,
            "subscription_id": billingInfo && billingInfo?.id || '',
            "billing_frequency_type": selectedPlan,
            "country_id": getValues2('country_id'),
            "State_id": getValues2('State_id'),
            "City": getValues2('City'),
        }
        if (paymentMethodId !== '') {
            form.paymentMethodId = paymentMethodId
            setCardError('')

            try {
    
                const response = await dispatch(Update_billing_detail(form));
                if (response?.payload?.status == true) {
                    dispatch(getAuthSettingDetail());
                    dispatch(getPlanFeatureActive());
                    onClose();
                    Logout();
                }
            } catch (error) {
                // Handle error if dispatch fails
                console.error("Error during signup:", error);
            }
        }else{
            setcheckSelectCard(true)
            setCardError('Please select card ')
        }
        console.log(form,'formformform');
        
    }

    const AddNewCard = async () => {
        const resp = await stripe.createPaymentMethod({
            type: "card",
            billing_details: {
                name: OrganizationDetail?.organization_name,
            },
            card: elements.getElement(CardNumberElement),
        });

        if (resp) {
            // Pass the payment method id to the onsubmit handler
            setPaymentMethodId(resp?.paymentMethod?.id);
        } else {
            console.error("Payment Method Error: ", resp.error);
        }
    }

    const onClose = () => {
        setModalIsOpen(false)
        setIncreasePlan(1)
        setValue1('card_number', '');
        setValue1('Expiry', '');
        setValue1('cvc', '');
        setSelectedPlan();
    }

    const Logout = () => {
        navigate('/login');
        // setShow(true);
        window.location.reload();
        localStorage.clear();
        clearStateAndPersistedData();
    }

    const handleCardNumberChange = (event) => {
        setComplete((prev) => ({ ...prev, cardNumber: event.complete }));
        setCardNumberError(event.error ? event.error.message : null);
    };

    const handleCvcChange = (event) => {
        setComplete((prev) => ({ ...prev, cvc: event.complete }));
        setCvcError(event.error ? event.error.message : null);
    };

    const handleExpirationChange = (event) => {
        setComplete((prev) => ({ ...prev, expiration: event.complete }));
        setExpirationError(event.error ? event.error.message : null);
    };

     // Toggle accordion open/close
  const handleToggle = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null); // Close if the same accordion is clicked
    } else {
      setOpenAccordion(index); // Open the selected accordion
    }
  };

    // Toggle subaccordion open/close
    const handleSubToggle = (index) => {
        if (openSubAccordion === index) {
          setOpenSubAccordion(null); // Close if the same accordion is clicked
        } else {
          setOpenSubAccordion(index); // Open the selected accordion
        }
      };

    const sortedCountryData = CountryData ?
        [CountryData.find(elem => elem.iso2 === "US"),
        ...CountryData.filter(elem => elem.iso2 !== "US")]
        : [];

    let monthAmountYerDiscount = subscriptionPlan?.yearlyPriceWithDiscount / 12;

    const handleSliderSelect = (id) => {
        setPaymentMethodId(id)
        setCardError('')
    }    

    const errorFunction = () => {
        console.log('hereeeee ===>>', errors2);
        
        let errorOne = !errors2?.country_id && !errors2?.State_id && !errors2?.City && !errors2?.address1
        setCheckStepOne(!errorOne)
    }
    useEffect(() => {
        errorFunction()
    }, [errors2])


    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg permision-popup payment-popup">
                <div className="modal-content">
                    <div className="modal-header role-icon payment">
                        <div className="logo-popup"><img src="/static/media/logo-dark.8b5ea850cb98041cfced.png" alt="LIFETRACKA" title="LIFETRACKA" /></div>
                        <div className='plan-name'>{subscriptionPlan?.type == 'update' ? `${subscriptionPlan?.name} Plan - Add more license` : `${subscriptionPlan?.name} Plan - ${subscriptionPlan?.actionText}`}</div>
                        <div className='cros_button'>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => onClose()}></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="sub-payment row">
                            <div className="accordion accordion-flush sap-accor-form">

                                <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button ${openAccordion === 0 ? '' : 'collapsed'}`} type="button" onClick={() => handleToggle(0)}>
                                                <div className="saf-title-head">
                                                    <div className="font-18 hd-title mb-3 mt-4"><BillingFrequencySvg />Billing Frequency</div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" className={`accordion-collapse ${openAccordion === 0 ? 'show' : 'collapse'}`} data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={1} checked={selectedPlan === 1} onChange={handlePlanChange} />
                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                        <div className="sub-payment-month-head">
                                                            <div className="font-16 hd-title" for='flexRadioDefault1'>Yearly <span className="badge text-bg-success">Save {subscriptionPlanDiscount?.year}%</span></div>
                                                            <div className="spmh-text">
                                                                <div className="font-16 hd-title">${monthAmountYerDiscount}/Month</div>
                                                                <div className="font-16 hd-subtitle">${subscriptionPlan?.yearlyPriceWithDiscount} billed yearly</div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={0} checked={selectedPlan === 0} onChange={handlePlanChange} />
                                                    <label className="form-check-label" for="flexRadioDefault2">
                                                        <div className="sub-payment-month-head">
                                                            <div className="font-16 hd-title" for='flexRadioDefault2'>Monthly</div>
                                                            <div className="spmh-text">
                                                                <div className="font-16 hd-title">${subscriptionPlan?.monthAmount}/Month</div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className={`accordion-button ${(openAccordion === 1 || checkSelectCard) ? '' : 'collapsed'}`} type="button" onClick={() => handleToggle(1)} >
                                            <div className="saf-title-head">
                                                <div className="font-18 hd-title mb-3 mt-4"><SavedCardsSvg />Saved Cards</div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour1" className={`accordion-collapse ${(openAccordion === 1 || checkSelectCard) ? 'show' : 'collapse'}`} data-bs-parent="#accordionFlushExample" >
                                        <div className="accordion-body">
                                            <Slider {...settings}>
                                                {CustomerAllCard && CustomerAllCard.length > 0 ? (
                                                    CustomerAllCard.map((item, index) => (
                                                        <div key={index}> {/* Ensure each card has a unique key */}
                                                            <div className={`debit-card ${paymentMethodId === item.id ? 'active' : ''}`} onClick={() =>  handleSliderSelect(item?.id)}>
                                                                <img src="https://image.ibb.co/gDVR4x/master_card_logo.png" className="master-card-logo" alt="MasterCard Logo" />
                                                                <div className="card-number-block">
                                                                    <p>xxxx xxxx xxxx {item?.card?.last4}</p>
                                                                </div>
                                                                <div className="card-holder-block">
                                                                    <div className="block-lebel">Card Holder</div>
                                                                    <p>{item?.customer_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No customer cards available</div>
                                                )}
                                            </Slider>
                                        </div>
                                        {cardError !== '' && <span className="error">{cardError}</span>}
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className={`accordion-button ${(openAccordion === 2 || checkStepOne) ? '' : 'collapsed'}`} type="button" onClick={() => handleToggle(2)} >
                                            <div className="saf-title-head">
                                                <div className="font-18 hd-title mb-3 mt-4"><AddNewCardSvg />Add new card</div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour2" className={`accordion-collapse ${(openAccordion === 2 || checkStepOne) ? 'show' : 'collapse'}`} data-bs-parent="#accordionFlushExample" >
                                        <div className="accordion-body">
                                            <form onSubmit={handleSubmit1(() => AddNewCard())}>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3 custom-form-group">
                                                        <label htmlFor="cardNumber" className="form-label">Card Number*</label>
                                                        <CardNumberElement className="form-control stripe-element" id='CardNumberElement' onChange={handleCardNumberChange} />
                                                        {cardNumberError && <p className="error">{cardNumberError}</p>}
                                                    </div>
                                                    {/* Expiry Date Field */}
                                                    <div className="col-md-6 mb-3 custom-form-group">
                                                        <label htmlFor="expiry" className="form-label">Expiration*</label>
                                                        <CardExpiryElement className="form-control stripe-element" id='CardExpiryElement' onChange={handleExpirationChange} />
                                                        {expirationError && <p className="error">{expirationError}</p>}
                                                    </div>
                                                    {/* CVC Field */}
                                                    <div className="col-md-6 mb-3 custom-form-group">
                                                        <label htmlFor="cvc" className="form-label">CVC*</label>
                                                        <CardCvcElement className="form-control stripe-element" id='CardCvcElement' onChange={handleCvcChange} />
                                                        {cvcError && <p className="error">{cvcError}</p>}
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                        />
                                                        <label className="form-check-label">Save card details</label>
                                                    </div>
                                                    <div className='col-md-12 mt-3'>
                                                        <button type="submit" className="btn btn-primary" >
                                                            Save card
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <form>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                    <button className={`accordion-button ${(openSubAccordion === 0 || checkStepOne) ? '' : 'collapsed'}`} type="button" onClick={() => handleSubToggle(0)} >
                                                            <div className="saf-title-head">
                                                                <div className="font-18 hd-title mb-3 mt-4"><BillingInformationSvg />Billing Information</div>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour3" className={`accordion-collapse ${(openSubAccordion === 0 || checkStepOne) ? 'show' : 'collapse'}`} data-bs-parent="#accordionFlushExample" >
                                                        <div className="accordion-body">
                                                            <div className='row'>
                                                                <div className="col-md-12 mb-3">
                                                                    <label for="" className="form-label">Country*</label>
                                                                    <select className="form-select" aria-label="Default select example" name='country_id' {...register2('country_id', { required: 'true' })} onChange={(e) => (setValue2('State_id', ''), setCountryID(e.target.value), e.target.value ? setShowMsg(prve => ({ ...prve, country: false })) : setShowMsg(prve => ({ ...prve, country: true })))}>
                                                                        <option value="">Select country</option>
                                                                        {sortedCountryData && sortedCountryData?.map((elem) => {
                                                                            return <option value={elem?.id}>{elem?.country}</option>
                                                                        })}
                                                                    </select>
                                                                    {showMsg?.country ? errors2.country_id && errors2.country_id.type === "required" && <span className="error">Country is required</span> : ''}
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <label for="" className="form-label">State*</label>
                                                                    <select className="form-select" aria-label="Default select example" name='State_id' {...register2('State_id', { required: 'true' })}>
                                                                        <option value="">Select state</option>
                                                                        {stateData && stateData?.map((elem) => {
                                                                            return <option value={elem?.id}>{elem?.address_state}</option>
                                                                        })}
                                                                    </select>
                                                                    {errors2.State_id && errors2.State_id.type === "required" && <span className="error">State is required</span>}
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <label for="" className="form-label">City*</label>
                                                                    <input type="input" className="form-control" id="" placeholder="City"  {...register2('City', { required: 'true' })} />
                                                                    {errors2.City && errors2.City.type === "required" && <span className="error">City is required</span>}
                                                                </div>
                                                                <div className="col-md-12 mb-3">
                                                                    <label for="" className="form-label">Address line*</label>
                                                                    <input type="input" className="form-control" id="" placeholder="Address" {...register2('address1', { required: "true" })} />
                                                                    {errors2.address1 && errors2.address1.type === "required" && <span className="error">Address is required</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className={`accordion-button ${openAccordion === 4 ? '' : 'collapsed'}`} type="button" onClick={() => handleToggle(4)} >
                                            <div className="saf-title-head">
                                                <div className="font-18 hd-title mb-3 mt-4"><OrderSummarySvg />Order Summary</div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour4" className={`accordion-collapse ${openAccordion === 4 ? 'show' : 'collapse'}`} data-bs-parent="#accordionFlushExample" >
                                        <div className="accordion-body">
                                            <div className="">
                                                <div className="font-16 hd-subtitle">Unlock unlimited access for only ({subscriptionPlan?.name}) ${totalAmount} USD
                                                    per {selectedPlan == 1 ? 'year' : 'Month'}. Charged next billing cycle {selectedPlan == 1 ? 'yearly' : 'Monthly'}, our
                                                    subscription offers unbeatable value and convenience. Cancel anytime and enjoy peace of mind with all sales final. Subscribe now!</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div>{showBillingError ? <p>Billing Information is required</p> : ''}</div>
                                <div className="col-md-12 d-flex justify-content-end p-2" onClick={handleSubmit2(() => onSubmit())}>
                                    <button type="submit" className="btn btn-primary" >
                                        {[`${subscriptionPlan?.actionText} to ${subscriptionPlan?.name}`]} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UpdateSubscription;